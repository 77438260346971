(function (root, factory) {
  // eslint-disable-next-line
  if (typeof define === "function" && define.amd) {
    // AMD. Register as an anonymous module.
    // eslint-disable-next-line
    define(["exports", "echarts"], factory);
  } else if (
    typeof exports === "object" &&
    typeof exports.nodeName !== "string"
  ) {
    // CommonJS
    factory(exports, require("echarts"));
  } else {
    // Browser globals
    factory({}, root.echarts);
  }
})(this, function (exports, echarts) {
  var log = function (msg) {
    if (typeof console !== "undefined") {
      console && console.error && console.error(msg);
    }
  };
  if (!echarts) {
    log("ECharts is not Loaded");
    return;
  }
  if (!echarts.registerMap) {
    log("ECharts Map is not loaded");
    return;
  }
  echarts.registerMap("温州市", {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          adcode: 330302,
          name: "鹿城区",
          center: [120.674231, 28.003352],
          centroid: [120.559924, 28.062487],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 0,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.807333, 27.982025],
                [120.802753, 27.978105],
                [120.799095, 27.975862],
                [120.792966, 27.974011],
                [120.783822, 27.973164],
                [120.773178, 27.974937],
                [120.762139, 27.982119],
                [120.750111, 27.990478],
                [120.746766, 27.987216],
                [120.741477, 27.989882],
                [120.737787, 27.992908],
                [120.735052, 27.993285],
                [120.734706, 27.995605],
                [120.732086, 27.99686],
                [120.72968, 27.99236],
                [120.725907, 27.993363],
                [120.725001, 27.991544],
                [120.715231, 27.993598],
                [120.713698, 27.992673],
                [120.707569, 27.981241],
                [120.704323, 27.976709],
                [120.700979, 27.975501],
                [120.695789, 27.975407],
                [120.692032, 27.977524],
                [120.681784, 27.981069],
                [120.680004, 27.984393],
                [120.673529, 27.983154],
                [120.662902, 27.979893],
                [120.659557, 27.978528],
                [120.661402, 27.975219],
                [120.65842, 27.971329],
                [120.659211, 27.969463],
                [120.654861, 27.967377],
                [120.65267, 27.968224],
                [120.652999, 27.969777],
                [120.648172, 27.970545],
                [120.646771, 27.97227],
                [120.64313, 27.974121],
                [120.646376, 27.979046],
                [120.645733, 27.981069],
                [120.641202, 27.982339],
                [120.639341, 27.987451],
                [120.642702, 27.993018],
                [120.641581, 27.996389],
                [120.640148, 28.008666],
                [120.638665, 28.010266],
                [120.63537, 28.009419],
                [120.634381, 28.006581],
                [120.630938, 28.002238],
                [120.627988, 28.001109],
                [120.625781, 27.997989],
                [120.621035, 27.997409],
                [120.619931, 27.996233],
                [120.615746, 27.995245],
                [120.613885, 27.995872],
                [120.613819, 27.99813],
                [120.608744, 28.001501],
                [120.605597, 28.000466],
                [120.601676, 28.00136],
                [120.599188, 27.999902],
                [120.595711, 27.999557],
                [120.588972, 28.000639],
                [120.584969, 28.002144],
                [120.583206, 28.00125],
                [120.58113, 28.00263],
                [120.579202, 28.008808],
                [120.575445, 28.011112],
                [120.571722, 28.012382],
                [120.567504, 28.015408],
                [120.562297, 28.024046],
                [120.561721, 28.026507],
                [120.554916, 28.033216],
                [120.551143, 28.031978],
                [120.54938, 28.029611],
                [120.543036, 28.028717],
                [120.538439, 28.026068],
                [120.530976, 28.024077],
                [120.529295, 28.028357],
                [120.526247, 28.027902],
                [120.521139, 28.031335],
                [120.521452, 28.033248],
                [120.519591, 28.036053],
                [120.513972, 28.031508],
                [120.508848, 28.03074],
                [120.503048, 28.02842],
                [120.499061, 28.029188],
                [120.501483, 28.035662],
                [120.504614, 28.039345],
                [120.498501, 28.041712],
                [120.493607, 28.042621],
                [120.481678, 28.043201],
                [120.480443, 28.043859],
                [120.473968, 28.050536],
                [120.469206, 28.057243],
                [120.466224, 28.058293],
                [120.460078, 28.054626],
                [120.456816, 28.051884],
                [120.450456, 28.048906],
                [120.448709, 28.044799],
                [120.447408, 28.043937],
                [120.438939, 28.046853],
                [120.433485, 28.047197],
                [120.428048, 28.045426],
                [120.426203, 28.046257],
                [120.427142, 28.05682],
                [120.428377, 28.060879],
                [120.429448, 28.06815],
                [120.427092, 28.075797],
                [120.427834, 28.081813],
                [120.425708, 28.081923],
                [120.421721, 28.080325],
                [120.420222, 28.078742],
                [120.416762, 28.077567],
                [120.412082, 28.081249],
                [120.404536, 28.087955],
                [120.401966, 28.096164],
                [120.397929, 28.098467],
                [120.399775, 28.101757],
                [120.40592, 28.109025],
                [120.411852, 28.11457],
                [120.412593, 28.117107],
                [120.411736, 28.119676],
                [120.406826, 28.122433],
                [120.405607, 28.124218],
                [120.409001, 28.129073],
                [120.408013, 28.13161],
                [120.405442, 28.132174],
                [120.401109, 28.134633],
                [120.400269, 28.13659],
                [120.397814, 28.137577],
                [120.390515, 28.13908],
                [120.385654, 28.142055],
                [120.385111, 28.146236],
                [120.385901, 28.147348],
                [120.397402, 28.152452],
                [120.404075, 28.154738],
                [120.410995, 28.156304],
                [120.418755, 28.155443],
                [120.426664, 28.154128],
                [120.43248, 28.156022],
                [120.436303, 28.15668],
                [120.442564, 28.155286],
                [120.445365, 28.153204],
                [120.45647, 28.138782],
                [120.461989, 28.13504],
                [120.46507, 28.134178],
                [120.467888, 28.135024],
                [120.469387, 28.139487],
                [120.473638, 28.143464],
                [120.475928, 28.143919],
                [120.485007, 28.143245],
                [120.490823, 28.141789],
                [120.496046, 28.135791],
                [120.500231, 28.133489],
                [120.511221, 28.133286],
                [120.518058, 28.133943],
                [120.525835, 28.135979],
                [120.532837, 28.137154],
                [120.544816, 28.137389],
                [120.557371, 28.1367],
                [120.559826, 28.134742],
                [120.560831, 28.128995],
                [120.560106, 28.118971],
                [120.561276, 28.112643],
                [120.564077, 28.106503],
                [120.566762, 28.105328],
                [120.571145, 28.105156],
                [120.575314, 28.106268],
                [120.579976, 28.106691],
                [120.586336, 28.105266],
                [120.592202, 28.101694],
                [120.593849, 28.099125],
                [120.599418, 28.081892],
                [120.600028, 28.073368],
                [120.597655, 28.058293],
                [120.597655, 28.052448],
                [120.598298, 28.043781],
                [120.600572, 28.037401],
                [120.603669, 28.032762],
                [120.607937, 28.029627],
                [120.613176, 28.028232],
                [120.620525, 28.027448],
                [120.645338, 28.03063],
                [120.653016, 28.030787],
                [120.670168, 28.030254],
                [120.685639, 28.028733],
                [120.712413, 28.025128],
                [120.726237, 28.022118],
                [120.73754, 28.021052],
                [120.745168, 28.022902],
                [120.753143, 28.022572],
                [120.774496, 28.017493],
                [120.785601, 28.011849],
                [120.788946, 28.008165],
                [120.791384, 28.003304],
                [120.793658, 27.996734],
                [120.805307, 27.983374],
                [120.807333, 27.982025],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330303,
          name: "龙湾区",
          center: [120.763469, 27.970254],
          centroid: [120.819332, 27.882665],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 1,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.736848, 27.869398],
                [120.737424, 27.86954],
                [120.738265, 27.875662],
                [120.732514, 27.879916],
                [120.725759, 27.88271],
                [120.72426, 27.883872],
                [120.725413, 27.885206],
                [120.731806, 27.885582],
                [120.739698, 27.88519],
                [120.740538, 27.880591],
                [120.742697, 27.877451],
                [120.745992, 27.875662],
                [120.754164, 27.876196],
                [120.754659, 27.881344],
                [120.752401, 27.882239],
                [120.744064, 27.883291],
                [120.744147, 27.886069],
                [120.74965, 27.887419],
                [120.751001, 27.893587],
                [120.748826, 27.902769],
                [120.744245, 27.909281],
                [120.742631, 27.915841],
                [120.743669, 27.921286],
                [120.748315, 27.925695],
                [120.748496, 27.930983],
                [120.746503, 27.934246],
                [120.742894, 27.935642],
                [120.737078, 27.936458],
                [120.731064, 27.938608],
                [120.725775, 27.942342],
                [120.719564, 27.947393],
                [120.719185, 27.951613],
                [120.726303, 27.961558],
                [120.728511, 27.961307],
                [120.729137, 27.963189],
                [120.719234, 27.968146],
                [120.720552, 27.971251],
                [120.725446, 27.969761],
                [120.726929, 27.976568],
                [120.721623, 27.979783],
                [120.712677, 27.98168],
                [120.707569, 27.981241],
                [120.713698, 27.992673],
                [120.715231, 27.993598],
                [120.725001, 27.991544],
                [120.725907, 27.993363],
                [120.72968, 27.99236],
                [120.732086, 27.99686],
                [120.734706, 27.995605],
                [120.735052, 27.993285],
                [120.737787, 27.992908],
                [120.741477, 27.989882],
                [120.746766, 27.987216],
                [120.750111, 27.990478],
                [120.762139, 27.982119],
                [120.773178, 27.974937],
                [120.783822, 27.973164],
                [120.792966, 27.974011],
                [120.799095, 27.975862],
                [120.802753, 27.978105],
                [120.807333, 27.982025],
                [120.81371, 27.97834],
                [120.82259, 27.977885],
                [120.833201, 27.980379],
                [120.83559, 27.966844],
                [120.841077, 27.962734],
                [120.850468, 27.956648],
                [120.862035, 27.94435],
                [120.865693, 27.944522],
                [120.878182, 27.938984],
                [120.883388, 27.937792],
                [120.890967, 27.933822],
                [120.94178, 27.896805],
                [120.91873, 27.872428],
                [120.910871, 27.86472],
                [120.883685, 27.820925],
                [120.840665, 27.758744],
                [120.809657, 27.775573],
                [120.798733, 27.762138],
                [120.77845, 27.797113],
                [120.773326, 27.799626],
                [120.769471, 27.802909],
                [120.763688, 27.808753],
                [120.753406, 27.818192],
                [120.754065, 27.819936],
                [120.750721, 27.822543],
                [120.750918, 27.823815],
                [120.748266, 27.824632],
                [120.747343, 27.826595],
                [120.747244, 27.833976],
                [120.748529, 27.837431],
                [120.750572, 27.837211],
                [120.751742, 27.842691],
                [120.754313, 27.845675],
                [120.758283, 27.846978],
                [120.758382, 27.849428],
                [120.756817, 27.852819],
                [120.754345, 27.853808],
                [120.752253, 27.86103],
                [120.747112, 27.866808],
                [120.736848, 27.869398],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330304,
          name: "瓯海区",
          center: [120.637145, 28.006444],
          centroid: [120.551173, 27.966589],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 2,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.707569, 27.981241],
                [120.712677, 27.98168],
                [120.721623, 27.979783],
                [120.726929, 27.976568],
                [120.725446, 27.969761],
                [120.720552, 27.971251],
                [120.719234, 27.968146],
                [120.729137, 27.963189],
                [120.728511, 27.961307],
                [120.726303, 27.961558],
                [120.719185, 27.951613],
                [120.719564, 27.947393],
                [120.725775, 27.942342],
                [120.731064, 27.938608],
                [120.737078, 27.936458],
                [120.742894, 27.935642],
                [120.746503, 27.934246],
                [120.748496, 27.930983],
                [120.748315, 27.925695],
                [120.743669, 27.921286],
                [120.742631, 27.915841],
                [120.744245, 27.909281],
                [120.748826, 27.902769],
                [120.751001, 27.893587],
                [120.74965, 27.887419],
                [120.744147, 27.886069],
                [120.744064, 27.883291],
                [120.752401, 27.882239],
                [120.754659, 27.881344],
                [120.754164, 27.876196],
                [120.745992, 27.875662],
                [120.742697, 27.877451],
                [120.740538, 27.880591],
                [120.739698, 27.88519],
                [120.731806, 27.885582],
                [120.725413, 27.885206],
                [120.72426, 27.883872],
                [120.725759, 27.88271],
                [120.732514, 27.879916],
                [120.738265, 27.875662],
                [120.737424, 27.86954],
                [120.736848, 27.869398],
                [120.730125, 27.868535],
                [120.718394, 27.869037],
                [120.716845, 27.871518],
                [120.723452, 27.874218],
                [120.723073, 27.875348],
                [120.716911, 27.875615],
                [120.706959, 27.873197],
                [120.702017, 27.869712],
                [120.697815, 27.868362],
                [120.687863, 27.867028],
                [120.685392, 27.865222],
                [120.684502, 27.862491],
                [120.68526, 27.858126],
                [120.684074, 27.856132],
                [120.679691, 27.852536],
                [120.674781, 27.850684],
                [120.668339, 27.850998],
                [120.659425, 27.850841],
                [120.653032, 27.848956],
                [120.645585, 27.850574],
                [120.640543, 27.854876],
                [120.63654, 27.856995],
                [120.627675, 27.85552],
                [120.616883, 27.851375],
                [120.614906, 27.851327],
                [120.614049, 27.853478],
                [120.61489, 27.855504],
                [120.618366, 27.85979],
                [120.622502, 27.868315],
                [120.622271, 27.871879],
                [120.619668, 27.875536],
                [120.615582, 27.878629],
                [120.611232, 27.880136],
                [120.614395, 27.884955],
                [120.614494, 27.887058],
                [120.612072, 27.889271],
                [120.606503, 27.892473],
                [120.601659, 27.896083],
                [120.598825, 27.902894],
                [120.597309, 27.904464],
                [120.593487, 27.902816],
                [120.588972, 27.898374],
                [120.583206, 27.897621],
                [120.5779, 27.895282],
                [120.575149, 27.899457],
                [120.572727, 27.901136],
                [120.567421, 27.902],
                [120.565411, 27.901184],
                [120.561819, 27.897746],
                [120.557058, 27.891359],
                [120.553697, 27.889915],
                [120.55228, 27.893556],
                [120.548342, 27.89602],
                [120.542031, 27.897417],
                [120.537995, 27.898939],
                [120.536314, 27.901419],
                [120.540845, 27.904008],
                [120.541998, 27.906002],
                [120.541059, 27.907696],
                [120.537764, 27.909234],
                [120.52941, 27.911745],
                [120.518849, 27.912263],
                [120.514746, 27.912075],
                [120.513033, 27.91719],
                [120.513708, 27.922792],
                [120.509771, 27.925115],
                [120.503279, 27.930041],
                [120.498089, 27.930763],
                [120.487725, 27.930073],
                [120.485138, 27.932803],
                [120.486012, 27.933744],
                [120.496161, 27.936929],
                [120.497628, 27.938153],
                [120.498287, 27.941322],
                [120.503147, 27.942985],
                [120.504284, 27.944459],
                [120.499407, 27.947173],
                [120.498979, 27.949919],
                [120.501582, 27.952727],
                [120.500659, 27.954311],
                [120.495832, 27.955911],
                [120.494612, 27.962577],
                [120.493393, 27.96322],
                [120.487923, 27.962624],
                [120.482848, 27.964365],
                [120.468761, 27.964805],
                [120.467311, 27.965307],
                [120.467146, 27.969102],
                [120.461248, 27.970718],
                [120.454723, 27.975705],
                [120.451741, 27.976191],
                [120.448133, 27.971502],
                [120.446617, 27.970906],
                [120.440521, 27.971596],
                [120.437489, 27.96893],
                [120.433716, 27.963487],
                [120.431805, 27.966655],
                [120.428509, 27.967063],
                [120.425741, 27.966389],
                [120.417058, 27.966091],
                [120.414125, 27.962248],
                [120.415295, 27.956585],
                [120.412626, 27.956538],
                [120.410649, 27.958091],
                [120.408177, 27.962405],
                [120.403399, 27.966012],
                [120.39961, 27.966593],
                [120.398605, 27.971408],
                [120.386626, 27.978591],
                [120.38198, 27.979516],
                [120.377268, 27.981696],
                [120.372358, 27.981947],
                [120.363708, 27.983844],
                [120.358798, 27.983562],
                [120.349126, 27.987702],
                [120.345172, 27.989129],
                [120.336835, 27.989945],
                [120.334891, 27.991136],
                [120.335171, 27.99686],
                [120.336407, 27.999667],
                [120.341201, 28.005923],
                [120.34448, 28.01903],
                [120.349176, 28.021679],
                [120.348154, 28.03726],
                [120.344908, 28.039988],
                [120.345452, 28.044],
                [120.347594, 28.046461],
                [120.3471, 28.049501],
                [120.35028, 28.052573],
                [120.354283, 28.052385],
                [120.357183, 28.050567],
                [120.363675, 28.05251],
                [120.36641, 28.051633],
                [120.370529, 28.053153],
                [120.372407, 28.054924],
                [120.374121, 28.063371],
                [120.373808, 28.066176],
                [120.371369, 28.069247],
                [120.37015, 28.072271],
                [120.36463, 28.07528],
                [120.362307, 28.080106],
                [120.36641, 28.083114],
                [120.365603, 28.085934],
                [120.36842, 28.088911],
                [120.376774, 28.093532],
                [120.383661, 28.095381],
                [120.387467, 28.09419],
                [120.390531, 28.094316],
                [120.396298, 28.099094],
                [120.397929, 28.098467],
                [120.401966, 28.096164],
                [120.404536, 28.087955],
                [120.412082, 28.081249],
                [120.416762, 28.077567],
                [120.420222, 28.078742],
                [120.421721, 28.080325],
                [120.425708, 28.081923],
                [120.427834, 28.081813],
                [120.427092, 28.075797],
                [120.429448, 28.06815],
                [120.428377, 28.060879],
                [120.427142, 28.05682],
                [120.426203, 28.046257],
                [120.428048, 28.045426],
                [120.433485, 28.047197],
                [120.438939, 28.046853],
                [120.447408, 28.043937],
                [120.448709, 28.044799],
                [120.450456, 28.048906],
                [120.456816, 28.051884],
                [120.460078, 28.054626],
                [120.466224, 28.058293],
                [120.469206, 28.057243],
                [120.473968, 28.050536],
                [120.480443, 28.043859],
                [120.481678, 28.043201],
                [120.493607, 28.042621],
                [120.498501, 28.041712],
                [120.504614, 28.039345],
                [120.501483, 28.035662],
                [120.499061, 28.029188],
                [120.503048, 28.02842],
                [120.508848, 28.03074],
                [120.513972, 28.031508],
                [120.519591, 28.036053],
                [120.521452, 28.033248],
                [120.521139, 28.031335],
                [120.526247, 28.027902],
                [120.529295, 28.028357],
                [120.530976, 28.024077],
                [120.538439, 28.026068],
                [120.543036, 28.028717],
                [120.54938, 28.029611],
                [120.551143, 28.031978],
                [120.554916, 28.033216],
                [120.561721, 28.026507],
                [120.562297, 28.024046],
                [120.567504, 28.015408],
                [120.571722, 28.012382],
                [120.575445, 28.011112],
                [120.579202, 28.008808],
                [120.58113, 28.00263],
                [120.583206, 28.00125],
                [120.584969, 28.002144],
                [120.588972, 28.000639],
                [120.595711, 27.999557],
                [120.599188, 27.999902],
                [120.601676, 28.00136],
                [120.605597, 28.000466],
                [120.608744, 28.001501],
                [120.613819, 27.99813],
                [120.613885, 27.995872],
                [120.615746, 27.995245],
                [120.619931, 27.996233],
                [120.621035, 27.997409],
                [120.625781, 27.997989],
                [120.627988, 28.001109],
                [120.630938, 28.002238],
                [120.634381, 28.006581],
                [120.63537, 28.009419],
                [120.638665, 28.010266],
                [120.640148, 28.008666],
                [120.641581, 27.996389],
                [120.642702, 27.993018],
                [120.639341, 27.987451],
                [120.641202, 27.982339],
                [120.645733, 27.981069],
                [120.646376, 27.979046],
                [120.64313, 27.974121],
                [120.646771, 27.97227],
                [120.648172, 27.970545],
                [120.652999, 27.969777],
                [120.65267, 27.968224],
                [120.654861, 27.967377],
                [120.659211, 27.969463],
                [120.65842, 27.971329],
                [120.661402, 27.975219],
                [120.659557, 27.978528],
                [120.662902, 27.979893],
                [120.673529, 27.983154],
                [120.680004, 27.984393],
                [120.681784, 27.981069],
                [120.692032, 27.977524],
                [120.695789, 27.975407],
                [120.700979, 27.975501],
                [120.704323, 27.976709],
                [120.707569, 27.981241],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330305,
          name: "洞头区",
          center: [121.156181, 27.836057],
          centroid: [121.033762, 27.892626],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 3,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.991835, 27.949746],
                [121.055912, 27.900148],
                [121.064084, 27.895926],
                [121.075585, 27.894435],
                [121.086163, 27.894451],
                [121.099459, 27.895063],
                [121.116743, 27.899787],
                [121.137404, 27.908826],
                [121.143665, 27.910709],
                [121.148262, 27.911447],
                [121.157917, 27.910301],
                [121.161146, 27.908795],
                [121.162201, 27.907194],
                [121.162745, 27.902518],
                [121.160388, 27.889459],
                [121.160388, 27.884641],
                [121.162728, 27.879304],
                [121.166798, 27.876886],
                [121.185185, 27.874814],
                [121.193028, 27.872381],
                [121.195879, 27.869806],
                [121.198597, 27.863166],
                [121.200558, 27.855158],
                [121.200558, 27.849271],
                [121.196636, 27.83396],
                [121.191957, 27.822684],
                [121.187888, 27.816873],
                [121.184362, 27.814093],
                [121.178101, 27.811988],
                [121.173125, 27.811391],
                [121.167177, 27.811674],
                [121.157736, 27.813889],
                [121.155248, 27.813041],
                [121.152727, 27.810669],
                [121.153172, 27.80902],
                [121.151211, 27.807025],
                [121.149498, 27.801134],
                [121.146351, 27.796736],
                [121.144159, 27.794961],
                [121.143846, 27.79174],
                [121.139925, 27.790169],
                [121.1363, 27.79174],
                [121.13663, 27.790169],
                [121.133944, 27.787027],
                [121.131473, 27.788818],
                [121.130715, 27.792007],
                [121.133516, 27.794332],
                [121.131522, 27.797521],
                [121.129034, 27.798448],
                [121.133038, 27.799328],
                [121.136218, 27.802218],
                [121.139892, 27.801213],
                [121.141853, 27.804292],
                [121.141144, 27.805957],
                [121.142512, 27.807794],
                [121.144868, 27.806177],
                [121.14841, 27.808046],
                [121.147306, 27.809507],
                [121.151046, 27.812381],
                [121.152496, 27.81535],
                [121.147273, 27.815554],
                [121.141919, 27.814957],
                [121.133021, 27.813198],
                [121.125689, 27.812868],
                [121.107664, 27.8137],
                [121.098701, 27.816496],
                [121.093033, 27.816622],
                [121.08987, 27.817548],
                [121.084713, 27.823061],
                [121.0699, 27.834212],
                [121.063854, 27.83451],
                [121.045087, 27.833112],
                [121.02782, 27.83261],
                [121.022811, 27.833662],
                [121.020092, 27.835908],
                [121.012134, 27.84624],
                [120.974255, 27.887199],
                [120.951452, 27.895894],
                [120.94178, 27.896805],
                [120.890967, 27.933822],
                [120.883388, 27.937792],
                [120.878182, 27.938984],
                [120.865693, 27.944522],
                [120.862035, 27.94435],
                [120.850468, 27.956648],
                [120.841077, 27.962734],
                [120.83559, 27.966844],
                [120.833201, 27.980379],
                [120.839545, 27.981853],
                [120.848821, 27.980975],
                [120.854192, 27.982872],
                [120.870718, 27.984064],
                [120.883817, 27.984205],
                [120.89189, 27.98466],
                [120.910311, 27.98179],
                [120.920229, 27.978748],
                [120.929687, 27.977995],
                [120.948503, 27.972961],
                [120.957367, 27.969839],
                [120.96666, 27.966091],
                [120.973975, 27.964538],
                [120.984635, 27.960601],
                [120.991835, 27.949746],
              ],
            ],
            [
              [
                [121.113118, 27.918336],
                [121.116265, 27.918807],
                [121.122295, 27.921349],
                [121.123811, 27.92127],
                [121.123679, 27.918838],
                [121.122229, 27.917975],
                [121.122097, 27.913252],
                [121.123926, 27.911353],
                [121.121405, 27.910631],
                [121.119264, 27.907618],
                [121.117039, 27.907838],
                [121.113381, 27.904417],
                [121.110729, 27.905233],
                [121.110185, 27.908277],
                [121.108076, 27.908716],
                [121.108175, 27.910945],
                [121.111503, 27.914272],
                [121.109542, 27.915762],
                [121.113118, 27.918336],
              ],
            ],
            [
              [
                [121.131028, 27.762453],
                [121.134669, 27.764574],
                [121.139101, 27.765155],
                [121.140386, 27.76294],
                [121.143599, 27.76184],
                [121.144357, 27.758508],
                [121.142083, 27.756261],
                [121.133137, 27.753134],
                [121.130616, 27.75744],
                [121.131917, 27.759294],
                [121.130171, 27.760928],
                [121.131028, 27.762453],
              ],
            ],
            [
              [
                [121.131044, 27.770152],
                [121.131061, 27.772022],
                [121.133153, 27.774018],
                [121.138294, 27.776673],
                [121.140024, 27.774191],
                [121.145428, 27.774096],
                [121.145065, 27.772274],
                [121.149844, 27.772006],
                [121.148674, 27.768895],
                [121.143154, 27.768518],
                [121.142611, 27.769178],
                [121.136976, 27.76701],
                [121.135608, 27.76778],
                [121.130731, 27.76789],
                [121.131044, 27.770152],
              ],
            ],
            [
              [
                [121.083642, 27.791913],
                [121.089342, 27.7935],
                [121.091056, 27.793232],
                [121.092259, 27.789415],
                [121.095406, 27.788409],
                [121.095587, 27.785016],
                [121.096922, 27.783068],
                [121.09473, 27.779894],
                [121.090529, 27.776233],
                [121.08519, 27.775589],
                [121.080973, 27.77573],
                [121.080643, 27.777993],
                [121.076557, 27.777223],
                [121.073542, 27.778024],
                [121.075222, 27.781277],
                [121.080182, 27.782581],
                [121.080297, 27.785424],
                [121.081697, 27.78753],
                [121.081351, 27.789541],
                [121.083642, 27.791913],
              ],
            ],
            [
              [
                [121.145774, 27.760677],
                [121.148328, 27.762547],
                [121.150206, 27.761274],
                [121.154029, 27.762138],
                [121.153287, 27.758304],
                [121.155165, 27.75612],
                [121.148328, 27.755098],
                [121.146713, 27.754155],
                [121.145708, 27.756434],
                [121.145774, 27.760677],
              ],
            ],
            [
              [
                [121.125689, 27.914805],
                [121.126991, 27.917049],
                [121.130813, 27.917489],
                [121.129973, 27.91581],
                [121.125689, 27.914805],
              ],
            ],
            [
              [
                [121.224086, 27.817863],
                [121.219753, 27.817407],
                [121.215749, 27.816056],
                [121.214134, 27.816983],
                [121.213278, 27.820109],
                [121.216886, 27.822229],
                [121.219094, 27.820957],
                [121.225042, 27.824506],
                [121.224416, 27.821821],
                [121.225585, 27.818632],
                [121.224086, 27.817863],
              ],
            ],
            [
              [
                [121.053589, 28.007741],
                [121.056835, 28.010877],
                [121.058021, 28.010062],
                [121.06331, 28.009529],
                [121.063557, 28.008322],
                [121.059273, 28.006613],
                [121.0541, 28.006895],
                [121.053589, 28.007741],
              ],
            ],
            [
              [
                [121.22168, 27.937211],
                [121.22318, 27.935517],
                [121.221961, 27.934011],
                [121.218006, 27.933242],
                [121.218566, 27.934874],
                [121.221549, 27.936019],
                [121.22168, 27.937211],
              ],
            ],
            [
              [
                [121.248142, 27.833913],
                [121.25002, 27.834651],
                [121.254617, 27.833018],
                [121.255029, 27.830411],
                [121.248586, 27.832735],
                [121.248142, 27.833913],
              ],
            ],
            [
              [
                [121.098157, 27.937509],
                [121.093247, 27.937635],
                [121.089672, 27.939361],
                [121.084762, 27.94413],
                [121.080264, 27.947173],
                [121.071235, 27.948742],
                [121.063541, 27.947801],
                [121.058762, 27.946075],
                [121.052781, 27.941824],
                [121.049997, 27.94129],
                [121.041759, 27.943926],
                [121.038315, 27.948946],
                [121.038035, 27.954766],
                [121.038711, 27.960209],
                [121.040573, 27.964318],
                [121.04428, 27.966703],
                [121.052386, 27.969902],
                [121.062206, 27.97627],
                [121.064595, 27.978795],
                [121.066457, 27.982245],
                [121.069785, 27.984503],
                [121.076013, 27.986369],
                [121.080659, 27.986369],
                [121.08636, 27.984519],
                [121.094318, 27.984127],
                [121.120664, 27.986636],
                [121.126497, 27.98557],
                [121.134026, 27.980535],
                [121.14991, 27.964883],
                [121.152941, 27.961558],
                [121.152941, 27.959048],
                [121.1507, 27.95588],
                [121.147389, 27.95337],
                [121.134554, 27.947283],
                [121.125953, 27.944114],
                [121.108488, 27.94082],
                [121.101205, 27.938969],
                [121.098157, 27.937509],
              ],
            ],
            [
              [
                [121.185861, 27.96297],
                [121.180902, 27.963409],
                [121.179419, 27.964287],
                [121.179864, 27.968647],
                [121.179336, 27.971078],
                [121.177425, 27.973008],
                [121.172647, 27.975188],
                [121.171345, 27.978591],
                [121.171955, 27.982951],
                [121.173866, 27.987216],
                [121.177343, 27.990509],
                [121.194989, 28.000153],
                [121.200294, 27.999698],
                [121.211515, 27.994288],
                [121.217001, 27.992015],
                [121.220132, 27.991481],
                [121.226228, 27.993912],
                [121.230133, 27.99479],
                [121.23814, 27.994429],
                [121.240233, 27.993739],
                [121.239887, 27.989819],
                [121.237531, 27.988157],
                [121.217611, 27.980426],
                [121.206209, 27.976866],
                [121.201678, 27.974874],
                [121.198202, 27.972098],
                [121.19143, 27.964695],
                [121.185861, 27.96297],
              ],
            ],
            [
              [
                [121.268737, 28.007663],
                [121.269577, 28.010281],
                [121.271175, 28.011191],
                [121.272856, 28.009748],
                [121.268737, 28.007663],
              ],
            ],
            [
              [
                [121.17207, 27.920407],
                [121.167325, 27.917285],
                [121.166847, 27.918053],
                [121.17207, 27.920407],
              ],
            ],
            [
              [
                [121.091863, 27.80492],
                [121.094796, 27.807653],
                [121.097301, 27.808486],
                [121.098981, 27.806161],
                [121.095785, 27.803538],
                [121.091863, 27.80492],
              ],
            ],
            [
              [
                [121.163865, 27.746627],
                [121.161575, 27.746832],
                [121.162151, 27.748277],
                [121.163865, 27.746627],
              ],
            ],
            [
              [
                [121.212223, 27.812632],
                [121.216688, 27.814894],
                [121.215617, 27.811831],
                [121.213294, 27.81081],
                [121.212223, 27.812632],
              ],
            ],
            [
              [
                [121.043967, 27.979312],
                [121.040787, 27.980786],
                [121.040012, 27.984723],
                [121.040737, 27.990274],
                [121.043275, 27.993363],
                [121.061629, 28.002599],
                [121.074102, 28.007444],
                [121.077331, 28.008024],
                [121.082109, 28.007647],
                [121.089079, 28.004935],
                [121.090381, 28.002944],
                [121.089606, 27.998679],
                [121.075453, 27.990023],
                [121.07112, 27.987624],
                [121.066852, 27.986134],
                [121.061745, 27.985162],
                [121.053984, 27.981602],
                [121.043967, 27.979312],
              ],
            ],
            [
              [
                [121.154012, 27.754092],
                [121.155742, 27.755255],
                [121.157637, 27.753935],
                [121.154012, 27.754092],
              ],
            ],
            [
              [
                [121.091171, 27.800569],
                [121.09674, 27.801621],
                [121.093791, 27.798354],
                [121.091171, 27.800569],
              ],
            ],
            [
              [
                [121.003023, 27.835672],
                [121.005247, 27.836426],
                [121.005725, 27.834761],
                [121.003023, 27.835672],
              ],
            ],
            [
              [
                [121.074415, 28.020472],
                [121.071103, 28.022494],
                [121.071696, 28.02958],
                [121.072932, 28.031069],
                [121.077199, 28.030457],
                [121.078715, 28.026444],
                [121.077611, 28.022118],
                [121.074415, 28.020472],
              ],
            ],
            [
              [
                [121.043159, 28.024971],
                [121.039946, 28.026147],
                [121.040853, 28.028467],
                [121.045005, 28.02715],
                [121.043159, 28.024971],
              ],
            ],
            [
              [
                [121.142726, 27.693996],
                [121.144769, 27.69442],
                [121.144242, 27.691999],
                [121.142726, 27.693996],
              ],
            ],
            [
              [
                [121.262443, 27.999823],
                [121.262624, 28.000999],
                [121.266611, 28.003916],
                [121.268226, 28.002458],
                [121.265112, 28.000262],
                [121.262443, 27.999823],
              ],
            ],
            [
              [
                [121.05438, 28.018935],
                [121.050722, 28.019171],
                [121.049272, 28.020378],
                [121.049025, 28.023058],
                [121.050244, 28.024516],
                [121.05756, 28.025253],
                [121.061465, 28.02356],
                [121.061465, 28.021381],
                [121.05779, 28.02113],
                [121.05438, 28.018935],
              ],
            ],
            [
              [
                [121.218402, 27.836646],
                [121.218583, 27.838703],
                [121.22089, 27.838938],
                [121.220659, 27.837368],
                [121.218402, 27.836646],
              ],
            ],
            [
              [
                [121.14673, 27.774332],
                [121.148361, 27.775762],
                [121.151623, 27.774426],
                [121.148921, 27.772682],
                [121.14673, 27.774332],
              ],
            ],
            [
              [
                [121.12938, 27.72786],
                [121.128029, 27.730548],
                [121.130451, 27.73113],
                [121.130797, 27.728835],
                [121.12938, 27.72786],
              ],
            ],
            [
              [
                [121.175876, 27.695474],
                [121.176618, 27.696606],
                [121.180967, 27.69648],
                [121.179336, 27.694058],
                [121.175876, 27.695474],
              ],
            ],
            [
              [
                [121.220214, 27.843351],
                [121.221796, 27.843932],
                [121.222554, 27.841388],
                [121.221038, 27.840666],
                [121.220214, 27.843351],
              ],
            ],
            [
              [
                [121.187212, 27.726933],
                [121.18886, 27.72808],
                [121.190491, 27.725911],
                [121.187212, 27.726933],
              ],
            ],
            [
              [
                [121.163503, 27.906347],
                [121.165265, 27.90823],
                [121.167078, 27.907948],
                [121.168066, 27.905829],
                [121.166715, 27.904934],
                [121.163503, 27.906347],
              ],
            ],
            [
              [
                [121.192138, 27.700254],
                [121.19606, 27.701684],
                [121.196653, 27.69942],
                [121.193176, 27.698666],
                [121.192138, 27.700254],
              ],
            ],
            [
              [
                [121.126909, 27.680283],
                [121.131275, 27.681243],
                [121.131192, 27.678223],
                [121.128408, 27.678207],
                [121.126909, 27.680283],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330324,
          name: "永嘉县",
          center: [120.690968, 28.153886],
          centroid: [120.662187, 28.330665],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 4,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.965572, 28.482972],
                [120.967006, 28.48049],
                [120.971504, 28.480209],
                [120.973926, 28.477883],
                [120.970136, 28.476057],
                [120.970153, 28.475058],
                [120.973843, 28.472201],
                [120.974008, 28.470515],
                [120.970054, 28.463178],
                [120.970878, 28.4621],
                [120.973547, 28.462694],
                [120.979083, 28.462678],
                [120.980236, 28.459758],
                [120.979725, 28.455824],
                [120.982263, 28.448595],
                [120.984882, 28.444894],
                [120.984125, 28.442974],
                [120.977864, 28.437883],
                [120.974156, 28.436353],
                [120.968241, 28.436165],
                [120.965539, 28.435088],
                [120.965638, 28.433448],
                [120.962969, 28.431746],
                [120.962508, 28.427858],
                [120.959295, 28.424547],
                [120.957944, 28.421579],
                [120.962475, 28.419065],
                [120.966973, 28.418393],
                [120.967121, 28.416941],
                [120.960184, 28.40735],
                [120.960465, 28.402289],
                [120.962985, 28.400774],
                [120.966775, 28.40054],
                [120.971372, 28.397837],
                [120.973151, 28.39779],
                [120.983993, 28.400587],
                [120.987189, 28.395963],
                [120.986596, 28.394338],
                [120.983548, 28.393401],
                [120.984322, 28.390979],
                [120.979264, 28.385964],
                [120.98251, 28.383277],
                [120.982543, 28.380824],
                [120.972608, 28.375715],
                [120.968423, 28.372684],
                [120.961239, 28.370746],
                [120.960843, 28.368183],
                [120.957729, 28.360901],
                [120.967302, 28.357917],
                [120.967121, 28.355291],
                [120.96544, 28.351056],
                [120.959311, 28.343538],
                [120.956032, 28.338099],
                [120.952951, 28.33688],
                [120.946789, 28.337959],
                [120.941846, 28.336317],
                [120.939045, 28.331784],
                [120.934778, 28.329815],
                [120.926639, 28.328908],
                [120.920295, 28.326204],
                [120.918648, 28.326501],
                [120.917791, 28.330596],
                [120.919801, 28.332128],
                [120.919587, 28.33738],
                [120.913128, 28.341178],
                [120.906719, 28.342397],
                [120.901858, 28.3411],
                [120.897064, 28.340694],
                [120.893752, 28.338521],
                [120.887062, 28.3323],
                [120.886585, 28.330221],
                [120.888117, 28.32686],
                [120.887194, 28.321123],
                [120.888677, 28.315261],
                [120.896174, 28.307366],
                [120.898349, 28.300674],
                [120.900804, 28.289009],
                [120.901512, 28.279641],
                [120.900919, 28.276201],
                [120.902039, 28.272557],
                [120.903835, 28.263532],
                [120.903835, 28.254804],
                [120.900458, 28.248734],
                [120.895993, 28.24742],
                [120.889715, 28.243603],
                [120.885481, 28.243431],
                [120.882531, 28.240568],
                [120.882152, 28.231228],
                [120.884937, 28.230586],
                [120.891808, 28.230774],
                [120.898217, 28.226831],
                [120.900441, 28.215049],
                [120.901133, 28.209149],
                [120.895647, 28.207068],
                [120.882927, 28.203139],
                [120.87917, 28.200182],
                [120.878231, 28.19414],
                [120.87627, 28.190556],
                [120.875232, 28.19054],
                [120.86767, 28.186158],
                [120.86678, 28.184467],
                [120.867554, 28.177048],
                [120.865511, 28.173478],
                [120.856367, 28.16122],
                [120.852446, 28.15657],
                [120.844553, 28.148538],
                [120.840879, 28.143057],
                [120.835656, 28.132659],
                [120.833696, 28.130404],
                [120.831323, 28.122401],
                [120.828851, 28.119159],
                [120.824205, 28.114445],
                [120.821091, 28.109683],
                [120.814319, 28.094833],
                [120.812243, 28.08902],
                [120.810909, 28.083286],
                [120.808355, 28.081939],
                [120.808635, 28.077301],
                [120.810695, 28.074105],
                [120.810826, 28.069028],
                [120.809146, 28.067335],
                [120.812672, 28.063089],
                [120.812309, 28.058999],
                [120.813364, 28.056178],
                [120.812013, 28.051523],
                [120.807399, 28.04585],
                [120.807053, 28.043405],
                [120.804104, 28.041931],
                [120.805653, 28.04016],
                [120.805373, 28.038091],
                [120.80272, 28.036492],
                [120.803264, 28.034141],
                [120.800611, 28.033545],
                [120.802242, 28.030614],
                [120.798996, 28.028717],
                [120.797102, 28.025551],
                [120.798057, 28.019845],
                [120.796723, 28.017697],
                [120.794614, 28.018089],
                [120.79402, 28.016223],
                [120.790874, 28.014765],
                [120.788419, 28.014906],
                [120.785601, 28.011849],
                [120.774496, 28.017493],
                [120.753143, 28.022572],
                [120.745168, 28.022902],
                [120.73754, 28.021052],
                [120.726237, 28.022118],
                [120.712413, 28.025128],
                [120.685639, 28.028733],
                [120.670168, 28.030254],
                [120.653016, 28.030787],
                [120.645338, 28.03063],
                [120.620525, 28.027448],
                [120.613176, 28.028232],
                [120.607937, 28.029627],
                [120.603669, 28.032762],
                [120.600572, 28.037401],
                [120.598298, 28.043781],
                [120.597655, 28.052448],
                [120.597655, 28.058293],
                [120.600028, 28.073368],
                [120.599418, 28.081892],
                [120.593849, 28.099125],
                [120.592202, 28.101694],
                [120.586336, 28.105266],
                [120.579976, 28.106691],
                [120.575314, 28.106268],
                [120.571145, 28.105156],
                [120.566762, 28.105328],
                [120.564077, 28.106503],
                [120.561276, 28.112643],
                [120.560106, 28.118971],
                [120.560831, 28.128995],
                [120.559826, 28.134742],
                [120.557371, 28.1367],
                [120.544816, 28.137389],
                [120.532837, 28.137154],
                [120.525835, 28.135979],
                [120.518058, 28.133943],
                [120.511221, 28.133286],
                [120.500231, 28.133489],
                [120.496046, 28.135791],
                [120.490823, 28.141789],
                [120.485007, 28.143245],
                [120.475928, 28.143919],
                [120.473638, 28.143464],
                [120.469387, 28.139487],
                [120.467888, 28.135024],
                [120.46507, 28.134178],
                [120.461989, 28.13504],
                [120.45647, 28.138782],
                [120.445365, 28.153204],
                [120.442564, 28.155286],
                [120.436303, 28.15668],
                [120.43248, 28.156022],
                [120.426664, 28.154128],
                [120.418755, 28.155443],
                [120.421573, 28.160203],
                [120.419909, 28.162582],
                [120.418871, 28.166731],
                [120.416037, 28.168218],
                [120.413664, 28.172617],
                [120.415394, 28.177126],
                [120.422083, 28.182824],
                [120.430223, 28.183841],
                [120.431047, 28.186925],
                [120.428756, 28.192231],
                [120.426647, 28.193686],
                [120.425165, 28.196378],
                [120.416712, 28.199806],
                [120.414092, 28.198413],
                [120.412099, 28.199258],
                [120.412231, 28.20436],
                [120.414043, 28.21087],
                [120.415773, 28.213906],
                [120.421573, 28.216003],
                [120.427834, 28.226722],
                [120.435462, 28.234013],
                [120.438428, 28.234466],
                [120.438296, 28.236798],
                [120.435907, 28.244323],
                [120.433485, 28.246998],
                [120.428954, 28.247092],
                [120.424588, 28.248281],
                [120.421424, 28.253834],
                [120.424786, 28.259121],
                [120.42645, 28.264236],
                [120.426433, 28.268068],
                [120.423484, 28.278687],
                [120.421787, 28.282753],
                [120.418525, 28.285725],
                [120.417042, 28.288852],
                [120.416646, 28.294747],
                [120.414158, 28.302909],
                [120.408573, 28.304989],
                [120.40279, 28.305974],
                [120.399379, 28.308491],
                [120.39498, 28.315495],
                [120.379937, 28.316981],
                [120.377696, 28.31759],
                [120.37249, 28.325156],
                [120.373396, 28.328596],
                [120.372111, 28.330612],
                [120.369969, 28.331331],
                [120.364268, 28.331315],
                [120.358353, 28.328846],
                [120.347627, 28.33266],
                [120.346474, 28.33441],
                [120.355288, 28.338381],
                [120.359638, 28.343335],
                [120.360775, 28.345883],
                [120.359721, 28.348415],
                [120.359391, 28.353775],
                [120.360165, 28.3549],
                [120.359852, 28.359386],
                [120.358023, 28.364402],
                [120.353492, 28.367215],
                [120.349357, 28.368011],
                [120.341959, 28.367715],
                [120.334676, 28.371355],
                [120.335385, 28.375105],
                [120.334314, 28.381261],
                [120.335022, 28.38448],
                [120.339801, 28.386089],
                [120.344694, 28.392385],
                [120.344052, 28.39476],
                [120.342256, 28.395588],
                [120.33957, 28.394822],
                [120.337642, 28.395525],
                [120.334133, 28.399634],
                [120.332914, 28.405491],
                [120.331694, 28.407585],
                [120.332848, 28.410302],
                [120.338812, 28.412364],
                [120.339735, 28.414535],
                [120.337379, 28.417893],
                [120.338219, 28.419736],
                [120.338186, 28.427108],
                [120.341201, 28.430512],
                [120.343788, 28.431902],
                [120.348269, 28.429966],
                [120.35603, 28.429732],
                [120.366739, 28.424297],
                [120.365273, 28.420502],
                [120.369343, 28.415098],
                [120.374319, 28.410818],
                [120.376691, 28.411583],
                [120.376807, 28.414941],
                [120.378289, 28.419705],
                [120.381684, 28.425593],
                [120.380744, 28.429404],
                [120.381024, 28.43629],
                [120.377499, 28.441647],
                [120.374121, 28.444129],
                [120.372144, 28.446987],
                [120.372012, 28.450875],
                [120.378075, 28.457104],
                [120.38432, 28.4589],
                [120.384847, 28.466909],
                [120.383957, 28.469485],
                [120.384979, 28.47142],
                [120.385275, 28.476759],
                [120.387796, 28.479491],
                [120.389691, 28.477102],
                [120.389246, 28.473606],
                [120.390301, 28.471951],
                [120.394436, 28.470936],
                [120.396628, 28.465566],
                [120.39905, 28.465082],
                [120.402856, 28.472279],
                [120.402839, 28.473762],
                [120.399165, 28.475619],
                [120.39928, 28.478023],
                [120.402411, 28.481645],
                [120.406019, 28.480443],
                [120.411291, 28.486312],
                [120.417272, 28.489527],
                [120.419167, 28.491884],
                [120.414949, 28.496847],
                [120.414933, 28.512359],
                [120.419085, 28.517899],
                [120.421128, 28.51885],
                [120.422199, 28.522158],
                [120.419942, 28.525326],
                [120.422891, 28.528836],
                [120.423599, 28.530958],
                [120.422743, 28.533611],
                [120.424225, 28.534281],
                [120.428773, 28.533517],
                [120.430668, 28.534515],
                [120.432183, 28.538899],
                [120.432315, 28.541895],
                [120.430223, 28.545498],
                [120.431113, 28.54879],
                [120.434161, 28.55375],
                [120.433979, 28.555934],
                [120.430223, 28.561487],
                [120.430701, 28.568178],
                [120.436253, 28.573324],
                [120.441872, 28.573324],
                [120.442514, 28.580841],
                [120.443486, 28.582619],
                [120.446337, 28.584319],
                [120.453257, 28.584194],
                [120.458925, 28.585894],
                [120.466553, 28.586018],
                [120.471891, 28.586954],
                [120.477658, 28.582011],
                [120.481431, 28.581356],
                [120.482272, 28.58329],
                [120.484562, 28.583882],
                [120.489801, 28.580826],
                [120.493508, 28.577769],
                [120.496557, 28.571749],
                [120.500544, 28.566743],
                [120.498435, 28.565105],
                [120.499473, 28.561877],
                [120.501582, 28.560005],
                [120.508436, 28.557275],
                [120.51244, 28.558835],
                [120.5188, 28.55584],
                [120.522968, 28.5557],
                [120.528751, 28.548368],
                [120.535556, 28.54659],
                [120.541207, 28.543345],
                [120.549198, 28.533579],
                [120.554718, 28.535966],
                [120.556465, 28.53592],
                [120.559414, 28.533158],
                [120.56785, 28.531894],
                [120.5693, 28.534578],
                [120.572232, 28.53748],
                [120.574852, 28.538072],
                [120.57968, 28.535576],
                [120.583354, 28.530802],
                [120.585858, 28.530459],
                [120.588758, 28.532908],
                [120.598792, 28.533969],
                [120.614527, 28.534063],
                [120.618613, 28.532768],
                [120.624298, 28.533377],
                [120.627511, 28.536356],
                [120.627165, 28.547417],
                [120.624792, 28.549975],
                [120.621497, 28.557151],
                [120.624726, 28.558289],
                [120.62616, 28.560972],
                [120.631284, 28.563998],
                [120.633294, 28.561721],
                [120.636688, 28.561128],
                [120.640016, 28.557852],
                [120.649638, 28.563],
                [120.648057, 28.565713],
                [120.648024, 28.56902],
                [120.639752, 28.576927],
                [120.640049, 28.578736],
                [120.64692, 28.580186],
                [120.653016, 28.58502],
                [120.65987, 28.583944],
                [120.661864, 28.581668],
                [120.669245, 28.579609],
                [120.675045, 28.582026],
                [120.679691, 28.581886],
                [120.687336, 28.582479],
                [120.689939, 28.586034],
                [120.695558, 28.586985],
                [120.697222, 28.587905],
                [120.701012, 28.59277],
                [120.701094, 28.599802],
                [120.702066, 28.602422],
                [120.701984, 28.606366],
                [120.705938, 28.610903],
                [120.709118, 28.611496],
                [120.713814, 28.609251],
                [120.718641, 28.605431],
                [120.721673, 28.604121],
                [120.729367, 28.603778],
                [120.732943, 28.605213],
                [120.73632, 28.609188],
                [120.739665, 28.61095],
                [120.743521, 28.611589],
                [120.749584, 28.609781],
                [120.752138, 28.605805],
                [120.755301, 28.605618],
                [120.757838, 28.604293],
                [120.756735, 28.599849],
                [120.756751, 28.595671],
                [120.758728, 28.591585],
                [120.760475, 28.591211],
                [120.764528, 28.592833],
                [120.771431, 28.592848],
                [120.776984, 28.589262],
                [120.782932, 28.590525],
                [120.785057, 28.588638],
                [120.787479, 28.584927],
                [120.791005, 28.583414],
                [120.793642, 28.583757],
                [120.798733, 28.588779],
                [120.800611, 28.587079],
                [120.801221, 28.583898],
                [120.798255, 28.576147],
                [120.802605, 28.572217],
                [120.811205, 28.570377],
                [120.825836, 28.566337],
                [120.82727, 28.56509],
                [120.828489, 28.56119],
                [120.82923, 28.55556],
                [120.833745, 28.551364],
                [120.842033, 28.545483],
                [120.843169, 28.542534],
                [120.841077, 28.539945],
                [120.838062, 28.53333],
                [120.834585, 28.530194],
                [120.833102, 28.527885],
                [120.83269, 28.524577],
                [120.838029, 28.517774],
                [120.842181, 28.517633],
                [120.845048, 28.514169],
                [120.848821, 28.513795],
                [120.854324, 28.515324],
                [120.860865, 28.509004],
                [120.861228, 28.506117],
                [120.860404, 28.50259],
                [120.86126, 28.499594],
                [120.863979, 28.496145],
                [120.866714, 28.494927],
                [120.869515, 28.491182],
                [120.882844, 28.488887],
                [120.887194, 28.489106],
                [120.890489, 28.486562],
                [120.894477, 28.485563],
                [120.904412, 28.486546],
                [120.910805, 28.489184],
                [120.912996, 28.492196],
                [120.914413, 28.495989],
                [120.917626, 28.49783],
                [120.927627, 28.505009],
                [120.930626, 28.50554],
                [120.934976, 28.505181],
                [120.939441, 28.503995],
                [120.944763, 28.503464],
                [120.948519, 28.500702],
                [120.953116, 28.499032],
                [120.954747, 28.499453],
                [120.956461, 28.503464],
                [120.954154, 28.506648],
                [120.954451, 28.509035],
                [120.960217, 28.510924],
                [120.961816, 28.51019],
                [120.962162, 28.506663],
                [120.960975, 28.504307],
                [120.960827, 28.499812],
                [120.95768, 28.494834],
                [120.958059, 28.493382],
                [120.961585, 28.491119],
                [120.962244, 28.488637],
                [120.965572, 28.482972],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330326,
          name: "平阳县",
          center: [120.564387, 27.6693],
          centroid: [120.381109, 27.633599],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 5,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.488104, 27.544782],
                [120.484414, 27.543538],
                [120.482156, 27.545932],
                [120.476093, 27.54738],
                [120.465037, 27.554198],
                [120.451757, 27.540972],
                [120.447029, 27.54083],
                [120.444771, 27.542389],
                [120.438955, 27.544546],
                [120.436088, 27.546593],
                [120.432876, 27.544892],
                [120.430799, 27.548104],
                [120.433007, 27.548561],
                [120.432464, 27.551222],
                [120.435495, 27.55267],
                [120.434984, 27.555662],
                [120.432233, 27.555772],
                [120.432612, 27.557346],
                [120.429531, 27.555756],
                [120.427653, 27.553442],
                [120.426005, 27.556748],
                [120.42383, 27.556103],
                [120.422067, 27.559692],
                [120.420634, 27.559299],
                [120.419958, 27.563486],
                [120.41719, 27.56791],
                [120.414455, 27.57013],
                [120.404783, 27.566808],
                [120.400121, 27.564824],
                [120.392393, 27.564651],
                [120.390317, 27.566666],
                [120.387055, 27.567123],
                [120.371666, 27.565769],
                [120.367613, 27.560936],
                [120.363263, 27.556874],
                [120.358864, 27.554938],
                [120.349126, 27.554387],
                [120.342948, 27.549144],
                [120.33527, 27.547254],
                [120.32517, 27.547774],
                [120.318727, 27.545585],
                [120.311873, 27.540074],
                [120.302284, 27.531681],
                [120.29169, 27.528028],
                [120.284621, 27.526028],
                [120.27841, 27.525902],
                [120.272116, 27.528154],
                [120.266712, 27.531476],
                [120.263367, 27.534846],
                [120.257715, 27.535791],
                [120.249724, 27.535366],
                [120.236543, 27.530091],
                [120.229936, 27.533665],
                [120.224911, 27.533823],
                [120.22557, 27.527949],
                [120.212883, 27.526752],
                [120.19438, 27.523933],
                [120.192387, 27.524705],
                [120.193754, 27.531697],
                [120.191069, 27.534279],
                [120.179848, 27.53376],
                [120.175482, 27.534673],
                [120.175037, 27.53302],
                [120.178168, 27.529728],
                [120.174955, 27.525571],
                [120.172516, 27.524862],
                [120.169534, 27.525902],
                [120.163553, 27.529744],
                [120.152481, 27.530862],
                [120.148972, 27.533429],
                [120.147423, 27.536468],
                [120.146994, 27.543255],
                [120.144507, 27.544971],
                [120.130271, 27.544467],
                [120.125575, 27.546262],
                [120.119215, 27.551112],
                [120.11218, 27.552623],
                [120.109494, 27.551458],
                [120.106875, 27.548561],
                [120.097219, 27.555268],
                [120.096066, 27.55689],
                [120.100317, 27.559173],
                [120.099823, 27.56081],
                [120.096577, 27.564604],
                [120.098636, 27.568713],
                [120.095918, 27.567327],
                [120.093067, 27.567406],
                [120.091057, 27.562951],
                [120.088833, 27.567312],
                [120.083956, 27.567076],
                [120.081896, 27.565942],
                [120.080348, 27.563376],
                [120.078997, 27.563675],
                [120.079343, 27.564777],
                [120.076542, 27.567579],
                [120.074548, 27.572223],
                [120.073609, 27.576741],
                [120.076311, 27.581274],
                [120.078881, 27.582093],
                [120.082753, 27.579622],
                [120.086032, 27.58151],
                [120.088602, 27.580472],
                [120.090398, 27.578016],
                [120.093364, 27.578079],
                [120.095621, 27.579669],
                [120.100169, 27.585508],
                [120.102047, 27.594055],
                [120.10348, 27.595078],
                [120.113564, 27.595078],
                [120.118968, 27.602286],
                [120.11821, 27.604583],
                [120.118556, 27.608517],
                [120.115393, 27.611318],
                [120.113053, 27.614623],
                [120.106298, 27.618651],
                [120.105589, 27.622899],
                [120.107072, 27.628485],
                [120.10694, 27.636745],
                [120.105474, 27.642692],
                [120.107781, 27.645334],
                [120.108176, 27.648197],
                [120.111076, 27.649393],
                [120.115969, 27.647552],
                [120.121769, 27.652523],
                [120.123829, 27.655889],
                [120.124026, 27.660152],
                [120.12167, 27.662574],
                [120.116645, 27.666255],
                [120.117667, 27.670564],
                [120.120056, 27.676084],
                [120.126037, 27.683916],
                [120.128162, 27.685756],
                [120.131243, 27.686825],
                [120.134522, 27.684938],
                [120.133665, 27.68206],
                [120.141211, 27.67934],
                [120.143386, 27.677972],
                [120.144721, 27.678774],
                [120.148724, 27.684152],
                [120.151097, 27.684262],
                [120.156089, 27.681274],
                [120.160291, 27.685724],
                [120.163932, 27.685992],
                [120.171561, 27.682406],
                [120.177509, 27.682422],
                [120.183753, 27.686982],
                [120.189751, 27.686951],
                [120.193277, 27.688995],
                [120.195484, 27.69225],
                [120.195221, 27.694719],
                [120.19901, 27.696024],
                [120.204991, 27.695945],
                [120.210527, 27.701496],
                [120.211878, 27.701779],
                [120.220413, 27.701071],
                [120.222901, 27.703367],
                [120.224763, 27.703257],
                [120.225471, 27.706747],
                [120.228931, 27.709954],
                [120.232886, 27.711511],
                [120.233709, 27.713193],
                [120.231436, 27.71901],
                [120.23193, 27.722217],
                [120.240185, 27.726241],
                [120.244979, 27.729401],
                [120.251833, 27.730422],
                [120.252904, 27.727797],
                [120.253514, 27.722547],
                [120.256496, 27.721729],
                [120.261176, 27.722044],
                [120.275807, 27.728929],
                [120.281293, 27.736065],
                [120.284737, 27.736348],
                [120.289745, 27.734258],
                [120.293271, 27.731271],
                [120.29861, 27.724575],
                [120.307837, 27.717076],
                [120.310802, 27.718334],
                [120.3139, 27.721006],
                [120.313817, 27.728332],
                [120.314476, 27.732104],
                [120.316437, 27.733912],
                [120.322171, 27.733739],
                [120.324955, 27.73432],
                [120.329173, 27.73803],
                [120.328366, 27.741472],
                [120.326175, 27.74515],
                [120.327015, 27.747146],
                [120.329536, 27.747319],
                [120.332946, 27.743311],
                [120.334545, 27.738769],
                [120.33756, 27.7366],
                [120.342668, 27.735106],
                [120.344002, 27.730925],
                [120.345221, 27.729668],
                [120.351021, 27.728112],
                [120.354563, 27.725062],
                [120.376988, 27.720975],
                [120.380003, 27.720912],
                [120.384468, 27.718522],
                [120.385209, 27.715771],
                [120.383858, 27.71236],
                [120.377416, 27.708115],
                [120.379278, 27.706323],
                [120.385819, 27.702392],
                [120.388422, 27.700269],
                [120.390152, 27.696905],
                [120.397616, 27.689986],
                [120.403169, 27.683177],
                [120.409561, 27.680079],
                [120.410715, 27.678679],
                [120.412692, 27.681243],
                [120.406612, 27.685724],
                [120.409957, 27.689624],
                [120.413796, 27.690756],
                [120.418591, 27.688193],
                [120.421276, 27.687454],
                [120.423566, 27.691197],
                [120.431014, 27.689483],
                [120.439993, 27.68541],
                [120.445019, 27.68173],
                [120.444837, 27.680614],
                [120.448429, 27.679214],
                [120.449747, 27.675487],
                [120.45474, 27.670894],
                [120.458134, 27.666585],
                [120.463192, 27.665689],
                [120.470211, 27.67069],
                [120.474989, 27.67566],
                [120.483458, 27.677091],
                [120.486572, 27.683428],
                [120.488401, 27.688634],
                [120.488351, 27.693791],
                [120.489439, 27.700537],
                [120.488318, 27.705033],
                [120.485995, 27.708587],
                [120.486901, 27.710992],
                [120.49219, 27.716746],
                [120.495156, 27.717878],
                [120.498105, 27.717328],
                [120.503872, 27.718271],
                [120.51127, 27.720519],
                [120.515076, 27.723867],
                [120.513675, 27.728174],
                [120.514466, 27.730297],
                [120.520711, 27.732371],
                [120.51931, 27.737244],
                [120.526972, 27.745291],
                [120.52829, 27.748938],
                [120.530415, 27.752049],
                [120.536924, 27.753432],
                [120.538472, 27.754658],
                [120.540219, 27.758587],
                [120.543349, 27.761573],
                [120.560304, 27.760944],
                [120.562347, 27.759153],
                [120.564637, 27.754092],
                [120.568492, 27.750022],
                [120.573814, 27.748372],
                [120.583041, 27.753087],
                [120.588247, 27.753621],
                [120.593586, 27.752002],
                [120.599517, 27.743861],
                [120.603093, 27.742745],
                [120.604081, 27.740938],
                [120.614016, 27.729621],
                [120.616636, 27.724889],
                [120.617065, 27.721698],
                [120.613885, 27.716762],
                [120.61171, 27.71585],
                [120.611875, 27.713445],
                [120.608217, 27.70953],
                [120.60848, 27.703037],
                [120.60596, 27.700301],
                [120.608464, 27.69909],
                [120.607937, 27.696166],
                [120.611397, 27.6942],
                [120.617081, 27.693587],
                [120.618366, 27.694263],
                [120.623408, 27.693571],
                [120.625797, 27.691228],
                [120.630526, 27.690127],
                [120.631086, 27.691385],
                [120.630575, 27.696653],
                [120.63878, 27.696747],
                [120.644547, 27.695521],
                [120.651896, 27.691983],
                [120.652275, 27.69126],
                [120.669344, 27.682139],
                [120.673875, 27.678805],
                [120.690022, 27.668582],
                [120.702115, 27.671697],
                [120.700666, 27.6694],
                [120.690664, 27.640458],
                [120.687633, 27.629209],
                [120.685359, 27.62301],
                [120.674995, 27.6091],
                [120.656097, 27.590151],
                [120.652044, 27.586846],
                [120.641087, 27.583509],
                [120.637676, 27.581148],
                [120.634875, 27.57726],
                [120.636342, 27.566477],
                [120.617509, 27.578315],
                [120.608678, 27.585461],
                [120.603587, 27.587287],
                [120.591015, 27.587476],
                [120.589104, 27.586594],
                [120.580751, 27.579795],
                [120.573715, 27.576473],
                [120.564769, 27.576473],
                [120.561029, 27.579511],
                [120.557124, 27.586327],
                [120.556563, 27.588735],
                [120.552098, 27.592685],
                [120.547749, 27.592748],
                [120.541092, 27.586028],
                [120.53699, 27.584218],
                [120.530827, 27.584674],
                [120.524352, 27.586956],
                [120.519623, 27.586988],
                [120.516295, 27.586122],
                [120.513231, 27.58417],
                [120.510973, 27.580361],
                [120.511814, 27.577197],
                [120.515307, 27.572333],
                [120.515933, 27.569059],
                [120.512291, 27.564982],
                [120.507068, 27.563093],
                [120.504432, 27.563817],
                [120.502422, 27.571231],
                [120.500791, 27.574458],
                [120.497595, 27.577622],
                [120.493541, 27.578882],
                [120.486111, 27.577182],
                [120.478927, 27.578189],
                [120.483722, 27.571027],
                [120.490938, 27.567076],
                [120.494678, 27.560637],
                [120.488763, 27.556244],
                [120.488351, 27.552056],
                [120.489192, 27.548781],
                [120.488104, 27.544782],
              ],
            ],
            [
              [
                [121.062667, 27.44357],
                [121.069027, 27.442972],
                [121.071136, 27.44056],
                [121.071219, 27.438574],
                [121.068533, 27.437471],
                [121.064265, 27.43889],
                [121.064117, 27.44138],
                [121.061497, 27.442798],
                [121.062667, 27.44357],
              ],
            ],
            [
              [
                [121.107285, 27.443996],
                [121.101354, 27.446785],
                [121.094005, 27.452979],
                [121.093379, 27.450788],
                [121.0901, 27.450914],
                [121.08608, 27.452411],
                [121.066358, 27.461299],
                [121.063788, 27.460306],
                [121.063359, 27.458305],
                [121.070082, 27.449543],
                [121.071943, 27.444973],
                [121.070609, 27.443838],
                [121.066276, 27.444973],
                [121.061497, 27.449811],
                [121.058565, 27.451797],
                [121.056143, 27.454649],
                [121.047987, 27.458053],
                [121.047756, 27.461882],
                [121.05003, 27.464687],
                [121.0526, 27.465112],
                [121.057362, 27.463426],
                [121.058861, 27.463867],
                [121.057065, 27.46735],
                [121.054989, 27.47392],
                [121.05578, 27.475196],
                [121.063277, 27.474267],
                [121.066226, 27.472297],
                [121.07051, 27.471163],
                [121.070444, 27.472864],
                [121.066226, 27.475212],
                [121.065419, 27.477134],
                [121.066852, 27.47841],
                [121.069488, 27.478253],
                [121.073443, 27.47559],
                [121.076507, 27.475023],
                [121.084152, 27.472392],
                [121.088716, 27.465018],
                [121.089359, 27.462623],
                [121.09272, 27.46048],
                [121.0988, 27.460716],
                [121.101173, 27.459455],
                [121.105423, 27.454886],
                [121.105835, 27.453168],
                [121.104418, 27.450631],
                [121.110465, 27.444894],
                [121.107285, 27.443996],
              ],
            ],
            [
              [
                [120.719416, 27.553631],
                [120.720717, 27.555048],
                [120.723057, 27.55267],
                [120.719416, 27.553631],
              ],
            ],
            [
              [
                [121.127568, 27.468689],
                [121.124997, 27.471037],
                [121.126859, 27.473589],
                [121.127897, 27.472707],
                [121.134933, 27.471147],
                [121.135427, 27.467381],
                [121.132362, 27.466467],
                [121.127568, 27.468689],
              ],
            ],
            [
              [
                [121.058037, 27.484003],
                [121.062651, 27.483751],
                [121.065287, 27.482538],
                [121.067231, 27.479576],
                [121.065386, 27.478599],
                [121.061811, 27.479923],
                [121.057461, 27.483074],
                [121.058037, 27.484003],
              ],
            ],
            [
              [
                [121.079028, 27.428723],
                [121.074646, 27.43175],
                [121.076936, 27.43413],
                [121.088074, 27.431592],
                [121.090018, 27.429338],
                [121.090413, 27.424531],
                [121.085223, 27.423711],
                [121.079028, 27.428723],
              ],
            ],
            [
              [
                [121.112986, 27.473148],
                [121.116644, 27.471667],
                [121.12073, 27.469036],
                [121.12335, 27.466326],
                [121.120071, 27.463836],
                [121.115688, 27.46319],
                [121.11236, 27.463505],
                [121.109081, 27.465506],
                [121.109674, 27.468799],
                [121.10656, 27.47318],
                [121.107763, 27.474393],
                [121.112986, 27.473148],
              ],
            ],
            [
              [
                [121.094565, 27.494038],
                [121.098602, 27.490573],
                [121.094598, 27.486319],
                [121.090018, 27.48739],
                [121.085668, 27.49229],
                [121.089128, 27.494684],
                [121.094565, 27.494038],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330327,
          name: "苍南县",
          center: [120.406256, 27.507743],
          centroid: [120.447536, 27.395126],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 6,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.078997, 27.563675],
                [120.080348, 27.563376],
                [120.081896, 27.565942],
                [120.083956, 27.567076],
                [120.088833, 27.567312],
                [120.091057, 27.562951],
                [120.093067, 27.567406],
                [120.095918, 27.567327],
                [120.098636, 27.568713],
                [120.096577, 27.564604],
                [120.099823, 27.56081],
                [120.100317, 27.559173],
                [120.096066, 27.55689],
                [120.097219, 27.555268],
                [120.106875, 27.548561],
                [120.109494, 27.551458],
                [120.11218, 27.552623],
                [120.119215, 27.551112],
                [120.125575, 27.546262],
                [120.130271, 27.544467],
                [120.144507, 27.544971],
                [120.146994, 27.543255],
                [120.147423, 27.536468],
                [120.148972, 27.533429],
                [120.152481, 27.530862],
                [120.163553, 27.529744],
                [120.169534, 27.525902],
                [120.172516, 27.524862],
                [120.174955, 27.525571],
                [120.178168, 27.529728],
                [120.175037, 27.53302],
                [120.175482, 27.534673],
                [120.179848, 27.53376],
                [120.191069, 27.534279],
                [120.193754, 27.531697],
                [120.192387, 27.524705],
                [120.19438, 27.523933],
                [120.212883, 27.526752],
                [120.22557, 27.527949],
                [120.224911, 27.533823],
                [120.229936, 27.533665],
                [120.236543, 27.530091],
                [120.249724, 27.535366],
                [120.257715, 27.535791],
                [120.263367, 27.534846],
                [120.266712, 27.531476],
                [120.272116, 27.528154],
                [120.27841, 27.525902],
                [120.284621, 27.526028],
                [120.29169, 27.528028],
                [120.302284, 27.531681],
                [120.311873, 27.540074],
                [120.318727, 27.545585],
                [120.32517, 27.547774],
                [120.33527, 27.547254],
                [120.342948, 27.549144],
                [120.349126, 27.554387],
                [120.358864, 27.554938],
                [120.363263, 27.556874],
                [120.367613, 27.560936],
                [120.371666, 27.565769],
                [120.387055, 27.567123],
                [120.390317, 27.566666],
                [120.392393, 27.564651],
                [120.400121, 27.564824],
                [120.404783, 27.566808],
                [120.414455, 27.57013],
                [120.41719, 27.56791],
                [120.419958, 27.563486],
                [120.420634, 27.559299],
                [120.422067, 27.559692],
                [120.42383, 27.556103],
                [120.426005, 27.556748],
                [120.427653, 27.553442],
                [120.429531, 27.555756],
                [120.432612, 27.557346],
                [120.432233, 27.555772],
                [120.434984, 27.555662],
                [120.435495, 27.55267],
                [120.432464, 27.551222],
                [120.433007, 27.548561],
                [120.430799, 27.548104],
                [120.432876, 27.544892],
                [120.436088, 27.546593],
                [120.438955, 27.544546],
                [120.444771, 27.542389],
                [120.447029, 27.54083],
                [120.451757, 27.540972],
                [120.465037, 27.554198],
                [120.476093, 27.54738],
                [120.482156, 27.545932],
                [120.484414, 27.543538],
                [120.488104, 27.544782],
                [120.485386, 27.540767],
                [120.490806, 27.539145],
                [120.489735, 27.537996],
                [120.488977, 27.531146],
                [120.486638, 27.526532],
                [120.486671, 27.524421],
                [120.483689, 27.52439],
                [120.477279, 27.521964],
                [120.481085, 27.517712],
                [120.479141, 27.5142],
                [120.472583, 27.509144],
                [120.466141, 27.506026],
                [120.46881, 27.501962],
                [120.468118, 27.501032],
                [120.472353, 27.498906],
                [120.476653, 27.499378],
                [120.476834, 27.496716],
                [120.480986, 27.49585],
                [120.484315, 27.496637],
                [120.484414, 27.494196],
                [120.486209, 27.490872],
                [120.492454, 27.491203],
                [120.498023, 27.495094],
                [120.502883, 27.49596],
                [120.507266, 27.49544],
                [120.509013, 27.49637],
                [120.514664, 27.495377],
                [120.516081, 27.497803],
                [120.516114, 27.500323],
                [120.51786, 27.499174],
                [120.516575, 27.504387],
                [120.5144, 27.506955],
                [120.515949, 27.507207],
                [120.515966, 27.509979],
                [120.520513, 27.510845],
                [120.521831, 27.514295],
                [120.520085, 27.515019],
                [120.521205, 27.516893],
                [120.529789, 27.518783],
                [120.530646, 27.52198],
                [120.526379, 27.527193],
                [120.52656, 27.528673],
                [120.529822, 27.52913],
                [120.530959, 27.532626],
                [120.532706, 27.530311],
                [120.53409, 27.531256],
                [120.537484, 27.530673],
                [120.538209, 27.528799],
                [120.540829, 27.528721],
                [120.540977, 27.527067],
                [120.545112, 27.527335],
                [120.544964, 27.528154],
                [120.550187, 27.527508],
                [120.549083, 27.525004],
                [120.550418, 27.52428],
                [120.553812, 27.525209],
                [120.554306, 27.523083],
                [120.557914, 27.524012],
                [120.560617, 27.522988],
                [120.561918, 27.519287],
                [120.567866, 27.520201],
                [120.567652, 27.521004],
                [120.576088, 27.523429],
                [120.576071, 27.519067],
                [120.579696, 27.51809],
                [120.580009, 27.516515],
                [120.58632, 27.51683],
                [120.585776, 27.514499],
                [120.58978, 27.514043],
                [120.587803, 27.513271],
                [120.586452, 27.510924],
                [120.586122, 27.507538],
                [120.588066, 27.507616],
                [120.588462, 27.504655],
                [120.586633, 27.499016],
                [120.588346, 27.498039],
                [120.587012, 27.494338],
                [120.586682, 27.490825],
                [120.587934, 27.490242],
                [120.586765, 27.488068],
                [120.591444, 27.484807],
                [120.586402, 27.481498],
                [120.584145, 27.481183],
                [120.584952, 27.479545],
                [120.582959, 27.477591],
                [120.588247, 27.473258],
                [120.585908, 27.470454],
                [120.588231, 27.46891],
                [120.590554, 27.470454],
                [120.593091, 27.470422],
                [120.597178, 27.467271],
                [120.599797, 27.468295],
                [120.59843, 27.470296],
                [120.600588, 27.474487],
                [120.603307, 27.477355],
                [120.606322, 27.478536],
                [120.606965, 27.476709],
                [120.61227, 27.476299],
                [120.617032, 27.474472],
                [120.616999, 27.469146],
                [120.619091, 27.468988],
                [120.619305, 27.470659],
                [120.622634, 27.470895],
                [120.623457, 27.472392],
                [120.627148, 27.467665],
                [120.63163, 27.468957],
                [120.630822, 27.470107],
                [120.636177, 27.469902],
                [120.642553, 27.468374],
                [120.647282, 27.471825],
                [120.650693, 27.470312],
                [120.653148, 27.467633],
                [120.654235, 27.468106],
                [120.655389, 27.473636],
                [120.653593, 27.475385],
                [120.653362, 27.478032],
                [120.654993, 27.477654],
                [120.658404, 27.480364],
                [120.654235, 27.48112],
                [120.657662, 27.481514],
                [120.65585, 27.484507],
                [120.661089, 27.485012],
                [120.663544, 27.488446],
                [120.664829, 27.49199],
                [120.668454, 27.48947],
                [120.669641, 27.490226],
                [120.66763, 27.494227],
                [120.670761, 27.495503],
                [120.670448, 27.496858],
                [120.667845, 27.496228],
                [120.667713, 27.499363],
                [120.665637, 27.498323],
                [120.662523, 27.498717],
                [120.662869, 27.500686],
                [120.667383, 27.50182],
                [120.673776, 27.505002],
                [120.681108, 27.512956],
                [120.683085, 27.501788],
                [120.686331, 27.492274],
                [120.689593, 27.488131],
                [120.692081, 27.486256],
                [120.697766, 27.483924],
                [120.701242, 27.481278],
                [120.702824, 27.478725],
                [120.702906, 27.472754],
                [120.699644, 27.465648],
                [120.683464, 27.442704],
                [120.676808, 27.431072],
                [120.674155, 27.424799],
                [120.673628, 27.419959],
                [120.67521, 27.403312],
                [120.675061, 27.382326],
                [120.673249, 27.369489],
                [120.670069, 27.362834],
                [120.665225, 27.35785],
                [120.657053, 27.352314],
                [120.6501, 27.349127],
                [120.637017, 27.345074],
                [120.612221, 27.336902],
                [120.580322, 27.321394],
                [120.576236, 27.318223],
                [120.572628, 27.313994],
                [120.571639, 27.310002],
                [120.572859, 27.302412],
                [120.575709, 27.293638],
                [120.575709, 27.290845],
                [120.574061, 27.286694],
                [120.568772, 27.281644],
                [120.563418, 27.27795],
                [120.552972, 27.257777],
                [120.553252, 27.254304],
                [120.554767, 27.252031],
                [120.574061, 27.243395],
                [120.576154, 27.241121],
                [120.576154, 27.238626],
                [120.574654, 27.234253],
                [120.571491, 27.228221],
                [120.56411, 27.22023],
                [120.558228, 27.210123],
                [120.556119, 27.204074],
                [120.554916, 27.182339],
                [120.553252, 27.17343],
                [120.545953, 27.156808],
                [120.543811, 27.154486],
                [120.537039, 27.149066],
                [120.531173, 27.145463],
                [120.522293, 27.14216],
                [120.492437, 27.136155],
                [120.490048, 27.136313],
                [120.467772, 27.143171],
                [120.464543, 27.143487],
                [120.46166, 27.142571],
                [120.457672, 27.146379],
                [120.449615, 27.156445],
                [120.445282, 27.160869],
                [120.441921, 27.161785],
                [120.437621, 27.161059],
                [120.436418, 27.162386],
                [120.43683, 27.165546],
                [120.434012, 27.169322],
                [120.426153, 27.173461],
                [120.424324, 27.178469],
                [120.426219, 27.18136],
                [120.425675, 27.183335],
                [120.418739, 27.186715],
                [120.416185, 27.19128],
                [120.408079, 27.191802],
                [120.407321, 27.192655],
                [120.410682, 27.19624],
                [120.40938, 27.199289],
                [120.404388, 27.203995],
                [120.40882, 27.209018],
                [120.407568, 27.210423],
                [120.403235, 27.210013],
                [120.400928, 27.21156],
                [120.405162, 27.213124],
                [120.406513, 27.214909],
                [120.404454, 27.217388],
                [120.405146, 27.219378],
                [120.409347, 27.222962],
                [120.413928, 27.230953],
                [120.409496, 27.235658],
                [120.398522, 27.242479],
                [120.397468, 27.245968],
                [120.401768, 27.250878],
                [120.410978, 27.255883],
                [120.415394, 27.256404],
                [120.41892, 27.252078],
                [120.42238, 27.250815],
                [120.426763, 27.252646],
                [120.429646, 27.25533],
                [120.430042, 27.258851],
                [120.426532, 27.26275],
                [120.417915, 27.270153],
                [120.414356, 27.271921],
                [120.408804, 27.276656],
                [120.403564, 27.285037],
                [120.402922, 27.294301],
                [120.401389, 27.298562],
                [120.391454, 27.306215],
                [120.387615, 27.308361],
                [120.383414, 27.3127],
                [120.381354, 27.316677],
                [120.378602, 27.323761],
                [120.37478, 27.329267],
                [120.372506, 27.330781],
                [120.367514, 27.331949],
                [120.358353, 27.337565],
                [120.357628, 27.340389],
                [120.353377, 27.343528],
                [120.351449, 27.346761],
                [120.352207, 27.349979],
                [120.355239, 27.353891],
                [120.353624, 27.357519],
                [120.346935, 27.360878],
                [120.343953, 27.363433],
                [120.343244, 27.366256],
                [120.346243, 27.369426],
                [120.345271, 27.37288],
                [120.348731, 27.378178],
                [120.351581, 27.383587],
                [120.351894, 27.386205],
                [120.349868, 27.390651],
                [120.346193, 27.394893],
                [120.34074, 27.399654],
                [120.331727, 27.396044],
                [120.327806, 27.396138],
                [120.321034, 27.398456],
                [120.319831, 27.399686],
                [120.323621, 27.403249],
                [120.322912, 27.406985],
                [120.319024, 27.408231],
                [120.316009, 27.406702],
                [120.316289, 27.401373],
                [120.314246, 27.397084],
                [120.315267, 27.393284],
                [120.312483, 27.391928],
                [120.309616, 27.393489],
                [120.309567, 27.394767],
                [120.306766, 27.392212],
                [120.302712, 27.392212],
                [120.294046, 27.396438],
                [120.289531, 27.395933],
                [120.285429, 27.393284],
                [120.282414, 27.389879],
                [120.27841, 27.38827],
                [120.273352, 27.389421],
                [120.267964, 27.397573],
                [120.266415, 27.400789],
                [120.259824, 27.407001],
                [120.258029, 27.410044],
                [120.25821, 27.412676],
                [120.262098, 27.417295],
                [120.263795, 27.418005],
                [120.26457, 27.426359],
                [120.263976, 27.4303],
                [120.262444, 27.4329],
                [120.250219, 27.439678],
                [120.248983, 27.439883],
                [120.244864, 27.447479],
                [120.240794, 27.452175],
                [120.231139, 27.455831],
                [120.227037, 27.455863],
                [120.220199, 27.45788],
                [120.216327, 27.45758],
                [120.215322, 27.460826],
                [120.210148, 27.463442],
                [120.206243, 27.462497],
                [120.203426, 27.457171],
                [120.200954, 27.458447],
                [120.198895, 27.462355],
                [120.198186, 27.47069],
                [120.196753, 27.472187],
                [120.190162, 27.474345],
                [120.189405, 27.475354],
                [120.180441, 27.479025],
                [120.176026, 27.478505],
                [120.173241, 27.472313],
                [120.17077, 27.473148],
                [120.168249, 27.475732],
                [120.16815, 27.479687],
                [120.169056, 27.482333],
                [120.168463, 27.486713],
                [120.166058, 27.490116],
                [120.160406, 27.494905],
                [120.158034, 27.498859],
                [120.146105, 27.49774],
                [120.147027, 27.504592],
                [120.143518, 27.507349],
                [120.131622, 27.50623],
                [120.131441, 27.509459],
                [120.135823, 27.511932],
                [120.136713, 27.515885],
                [120.131408, 27.518074],
                [120.127684, 27.518074],
                [120.122362, 27.51609],
                [120.11625, 27.517303],
                [120.111109, 27.517019],
                [120.111455, 27.521492],
                [120.109445, 27.522768],
                [120.104766, 27.523539],
                [120.098488, 27.528028],
                [120.096923, 27.531728],
                [120.094089, 27.536059],
                [120.090332, 27.539035],
                [120.086839, 27.54072],
                [120.0787, 27.540987],
                [120.072027, 27.541712],
                [120.067546, 27.543806],
                [120.066227, 27.546231],
                [120.069654, 27.551253],
                [120.068913, 27.553363],
                [120.069292, 27.557866],
                [120.067364, 27.560999],
                [120.06888, 27.564116],
                [120.072686, 27.564179],
                [120.076921, 27.563187],
                [120.078997, 27.563675],
              ],
            ],
            [
              [
                [120.705427, 27.524469],
                [120.704208, 27.525823],
                [120.707734, 27.526579],
                [120.708475, 27.525256],
                [120.705427, 27.524469],
              ],
            ],
            [
              [
                [120.832246, 27.043159],
                [120.83475, 27.044756],
                [120.835607, 27.041957],
                [120.83241, 27.041356],
                [120.832246, 27.043159],
              ],
            ],
            [
              [
                [120.8362, 27.043776],
                [120.836908, 27.044962],
                [120.838408, 27.042431],
                [120.8362, 27.043776],
              ],
            ],
            [
              [
                [120.848195, 27.052759],
                [120.847354, 27.055321],
                [120.849562, 27.055574],
                [120.848195, 27.052759],
              ],
            ],
            [
              [
                [120.857504, 27.059733],
                [120.856631, 27.062169],
                [120.858987, 27.062533],
                [120.857504, 27.059733],
              ],
            ],
            [
              [
                [120.846168, 27.065727],
                [120.845344, 27.067198],
                [120.848129, 27.067577],
                [120.846168, 27.065727],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330328,
          name: "文成县",
          center: [120.09245, 27.789133],
          centroid: [120.022316, 27.807567],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 7,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.229953, 27.931344],
                [120.227761, 27.929367],
                [120.227712, 27.924361],
                [120.229261, 27.922023],
                [120.229673, 27.917269],
                [120.224549, 27.91413],
                [120.220759, 27.906002],
                [120.223873, 27.903192],
                [120.228684, 27.893226],
                [120.22763, 27.888816],
                [120.224862, 27.888533],
                [120.216492, 27.889993],
                [120.2143, 27.888565],
                [120.212488, 27.881172],
                [120.210676, 27.877734],
                [120.198697, 27.871141],
                [120.19298, 27.870183],
                [120.191612, 27.862852],
                [120.198005, 27.846962],
                [120.198121, 27.844544],
                [120.195995, 27.833521],
                [120.194726, 27.83027],
                [120.190525, 27.824946],
                [120.189915, 27.822229],
                [120.186488, 27.815821],
                [120.185401, 27.812727],
                [120.176438, 27.806177],
                [120.174839, 27.804182],
                [120.175087, 27.800506],
                [120.181133, 27.799862],
                [120.188004, 27.794741],
                [120.196852, 27.791269],
                [120.197939, 27.789651],
                [120.196967, 27.78588],
                [120.197857, 27.781465],
                [120.20023, 27.779737],
                [120.208649, 27.779548],
                [120.210544, 27.778401],
                [120.212504, 27.773735],
                [120.217629, 27.768047],
                [120.223329, 27.762767],
                [120.224153, 27.758351],
                [120.232012, 27.754894],
                [120.236362, 27.751169],
                [120.238867, 27.747602],
                [120.243348, 27.744301],
                [120.254667, 27.740781],
                [120.256875, 27.739476],
                [120.256381, 27.736002],
                [120.251833, 27.730422],
                [120.244979, 27.729401],
                [120.240185, 27.726241],
                [120.23193, 27.722217],
                [120.231436, 27.71901],
                [120.233709, 27.713193],
                [120.232886, 27.711511],
                [120.228931, 27.709954],
                [120.225471, 27.706747],
                [120.224763, 27.703257],
                [120.222901, 27.703367],
                [120.220413, 27.701071],
                [120.211878, 27.701779],
                [120.210527, 27.701496],
                [120.204991, 27.695945],
                [120.19901, 27.696024],
                [120.195221, 27.694719],
                [120.195484, 27.69225],
                [120.193277, 27.688995],
                [120.189751, 27.686951],
                [120.183753, 27.686982],
                [120.177509, 27.682422],
                [120.171561, 27.682406],
                [120.163932, 27.685992],
                [120.160291, 27.685724],
                [120.156089, 27.681274],
                [120.151097, 27.684262],
                [120.148724, 27.684152],
                [120.144721, 27.678774],
                [120.143386, 27.677972],
                [120.141211, 27.67934],
                [120.133665, 27.68206],
                [120.134522, 27.684938],
                [120.131243, 27.686825],
                [120.128162, 27.685756],
                [120.126037, 27.683916],
                [120.120056, 27.676084],
                [120.117667, 27.670564],
                [120.116645, 27.666255],
                [120.12167, 27.662574],
                [120.124026, 27.660152],
                [120.123829, 27.655889],
                [120.121769, 27.652523],
                [120.115969, 27.647552],
                [120.111076, 27.649393],
                [120.108176, 27.648197],
                [120.107781, 27.645334],
                [120.105474, 27.642692],
                [120.10694, 27.636745],
                [120.107072, 27.628485],
                [120.105589, 27.622899],
                [120.106298, 27.618651],
                [120.113053, 27.614623],
                [120.115393, 27.611318],
                [120.118556, 27.608517],
                [120.11821, 27.604583],
                [120.118968, 27.602286],
                [120.113564, 27.595078],
                [120.10348, 27.595078],
                [120.102047, 27.594055],
                [120.100169, 27.585508],
                [120.095621, 27.579669],
                [120.093364, 27.578079],
                [120.090398, 27.578016],
                [120.088602, 27.580472],
                [120.086032, 27.58151],
                [120.082753, 27.579622],
                [120.078881, 27.582093],
                [120.076311, 27.581274],
                [120.073609, 27.576741],
                [120.074548, 27.572223],
                [120.076542, 27.567579],
                [120.079343, 27.564777],
                [120.078997, 27.563675],
                [120.076921, 27.563187],
                [120.072686, 27.564179],
                [120.06888, 27.564116],
                [120.060197, 27.566918],
                [120.048186, 27.573593],
                [120.046917, 27.575104],
                [120.048235, 27.578268],
                [120.049372, 27.584375],
                [120.04578, 27.587192],
                [120.038877, 27.586311],
                [120.032929, 27.58491],
                [120.029073, 27.586579],
                [120.026898, 27.589349],
                [120.023471, 27.591049],
                [120.016996, 27.593079],
                [120.014953, 27.59566],
                [120.015348, 27.599185],
                [120.017194, 27.603844],
                [120.021, 27.608989],
                [120.014624, 27.613868],
                [120.008511, 27.616448],
                [120.01146, 27.618604],
                [120.009137, 27.61969],
                [120.009005, 27.621546],
                [120.005924, 27.621405],
                [120.005232, 27.618761],
                [120.000306, 27.624552],
                [119.990107, 27.628564],
                [119.987602, 27.632041],
                [119.988937, 27.636399],
                [119.988031, 27.640159],
                [119.989365, 27.643447],
                [119.988953, 27.646294],
                [119.987503, 27.647946],
                [119.988476, 27.651186],
                [119.990667, 27.651123],
                [119.994704, 27.65364],
                [119.998823, 27.661159],
                [119.999333, 27.663141],
                [119.99823, 27.667387],
                [119.996615, 27.669039],
                [119.996664, 27.673033],
                [119.992628, 27.672278],
                [119.991227, 27.67459],
                [119.997126, 27.67643],
                [119.997735, 27.678522],
                [119.996417, 27.679922],
                [119.989678, 27.683083],
                [119.987372, 27.685064],
                [119.987998, 27.691448],
                [119.979825, 27.696134],
                [119.97307, 27.696496],
                [119.970022, 27.694876],
                [119.968325, 27.689656],
                [119.965227, 27.684875],
                [119.954765, 27.683224],
                [119.942754, 27.68206],
                [119.930331, 27.689892],
                [119.925272, 27.68758],
                [119.92341, 27.681651],
                [119.920857, 27.682453],
                [119.921697, 27.68714],
                [119.920165, 27.688948],
                [119.917759, 27.689514],
                [119.914744, 27.688807],
                [119.908516, 27.684608],
                [119.914629, 27.67923],
                [119.903342, 27.673662],
                [119.8998, 27.674512],
                [119.895549, 27.672797],
                [119.894198, 27.673348],
                [119.886586, 27.679513],
                [119.882945, 27.684215],
                [119.881577, 27.692203],
                [119.877623, 27.695568],
                [119.876403, 27.697895],
                [119.876914, 27.703414],
                [119.878348, 27.706338],
                [119.876156, 27.707816],
                [119.879715, 27.717218],
                [119.879913, 27.721572],
                [119.877145, 27.724025],
                [119.869747, 27.725864],
                [119.866287, 27.729086],
                [119.865249, 27.732481],
                [119.861987, 27.735232],
                [119.854984, 27.736678],
                [119.852216, 27.742227],
                [119.848888, 27.74625],
                [119.841935, 27.748953],
                [119.839315, 27.751829],
                [119.834109, 27.752804],
                [119.831225, 27.754139],
                [119.830715, 27.755962],
                [119.832049, 27.760017],
                [119.835954, 27.766617],
                [119.836597, 27.770671],
                [119.835888, 27.777899],
                [119.832346, 27.787184],
                [119.835624, 27.792777],
                [119.835542, 27.797081],
                [119.833944, 27.800349],
                [119.830088, 27.802988],
                [119.825211, 27.804653],
                [119.821224, 27.804386],
                [119.82012, 27.806098],
                [119.816907, 27.807826],
                [119.81264, 27.808281],
                [119.805621, 27.807732],
                [119.800382, 27.805768],
                [119.79791, 27.800961],
                [119.79646, 27.794976],
                [119.793396, 27.786728],
                [119.792325, 27.785126],
                [119.789738, 27.786791],
                [119.78982, 27.791033],
                [119.788881, 27.793971],
                [119.785059, 27.795777],
                [119.786179, 27.801433],
                [119.784746, 27.803977],
                [119.785289, 27.80591],
                [119.788041, 27.808517],
                [119.78374, 27.812287],
                [119.784119, 27.819763],
                [119.785751, 27.822496],
                [119.79389, 27.826375],
                [119.793462, 27.829626],
                [119.788288, 27.830662],
                [119.785784, 27.833505],
                [119.785289, 27.846146],
                [119.785619, 27.848077],
                [119.78898, 27.85318],
                [119.788931, 27.856069],
                [119.785965, 27.859351],
                [119.785075, 27.861658],
                [119.785948, 27.863919],
                [119.790117, 27.86709],
                [119.797235, 27.880936],
                [119.800003, 27.888439],
                [119.800184, 27.890558],
                [119.798503, 27.894843],
                [119.795669, 27.895251],
                [119.79104, 27.898578],
                [119.789046, 27.901905],
                [119.789936, 27.907414],
                [119.786459, 27.909297],
                [119.787629, 27.911478],
                [119.791402, 27.913989],
                [119.793214, 27.916155],
                [119.797449, 27.917536],
                [119.799261, 27.919654],
                [119.804715, 27.922118],
                [119.811717, 27.922039],
                [119.815128, 27.920705],
                [119.819247, 27.916076],
                [119.825294, 27.911761],
                [119.833071, 27.911337],
                [119.835213, 27.913456],
                [119.837124, 27.920046],
                [119.842643, 27.92593],
                [119.8464, 27.929084],
                [119.850618, 27.930826],
                [119.851162, 27.932206],
                [119.846614, 27.936929],
                [119.837981, 27.944538],
                [119.831423, 27.951629],
                [119.833598, 27.956193],
                [119.841474, 27.959581],
                [119.84607, 27.961009],
                [119.850931, 27.96035],
                [119.854852, 27.961919],
                [119.860075, 27.954672],
                [119.86202, 27.949542],
                [119.863568, 27.949056],
                [119.865694, 27.950844],
                [119.865892, 27.958248],
                [119.866831, 27.960146],
                [119.865331, 27.964491],
                [119.865529, 27.966373],
                [119.868281, 27.967973],
                [119.875234, 27.974152],
                [119.876453, 27.973917],
                [119.879089, 27.96962],
                [119.88161, 27.967487],
                [119.882895, 27.964616],
                [119.88825, 27.957621],
                [119.892122, 27.951362],
                [119.895071, 27.952005],
                [119.89835, 27.954907],
                [119.90293, 27.955315],
                [119.906835, 27.954248],
                [119.911086, 27.956225],
                [119.91537, 27.960303],
                [119.923295, 27.964758],
                [119.928617, 27.96882],
                [119.945571, 27.97387],
                [119.948735, 27.973478],
                [119.955375, 27.974921],
                [119.958555, 27.973713],
                [119.960301, 27.976082],
                [119.962443, 27.976207],
                [119.966035, 27.9702],
                [119.966282, 27.967957],
                [119.968671, 27.966734],
                [119.968753, 27.962499],
                [119.964618, 27.959393],
                [119.966298, 27.957511],
                [119.963646, 27.953856],
                [119.962146, 27.950232],
                [119.963003, 27.947377],
                [119.963003, 27.940616],
                [119.966908, 27.937588],
                [119.97999, 27.939141],
                [119.989349, 27.939627],
                [119.993303, 27.940428],
                [120.001245, 27.941008],
                [120.00688, 27.942906],
                [120.006731, 27.947048],
                [120.009384, 27.947911],
                [120.013833, 27.943534],
                [120.020506, 27.939596],
                [120.027656, 27.939643],
                [120.031792, 27.941322],
                [120.035927, 27.947895],
                [120.038415, 27.949683],
                [120.042996, 27.951111],
                [120.046275, 27.950923],
                [120.063542, 27.951064],
                [120.06575, 27.952036],
                [120.068353, 27.955268],
                [120.067859, 27.95737],
                [120.064316, 27.961244],
                [120.06542, 27.964444],
                [120.068172, 27.9678],
                [120.074284, 27.972302],
                [120.076986, 27.971972],
                [120.078749, 27.967393],
                [120.084401, 27.96711],
                [120.092211, 27.974545],
                [120.095704, 27.976019],
                [120.096906, 27.980614],
                [120.099048, 27.982731],
                [120.100515, 27.981147],
                [120.10615, 27.982245],
                [120.117436, 27.982606],
                [120.127322, 27.980724],
                [120.129645, 27.979438],
                [120.132413, 27.979438],
                [120.135774, 27.977195],
                [120.141425, 27.976144],
                [120.14388, 27.976599],
                [120.145759, 27.979673],
                [120.149351, 27.980128],
                [120.153404, 27.977964],
                [120.156946, 27.978293],
                [120.160472, 27.97743],
                [120.161543, 27.980238],
                [120.168035, 27.980488],
                [120.17194, 27.978575],
                [120.175136, 27.97547],
                [120.176701, 27.972208],
                [120.176553, 27.966655],
                [120.174856, 27.960381],
                [120.176866, 27.959676],
                [120.177311, 27.954797],
                [120.178332, 27.952962],
                [120.187856, 27.94857],
                [120.192519, 27.944459],
                [120.192535, 27.941839],
                [120.197758, 27.937478],
                [120.202042, 27.935972],
                [120.207644, 27.936301],
                [120.21402, 27.934905],
                [120.218667, 27.935658],
                [120.226905, 27.933399],
                [120.229953, 27.931344],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330329,
          name: "泰顺县",
          center: [119.71624, 27.557309],
          centroid: [119.877783, 27.531151],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 8,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.785059, 27.795777],
                [119.788881, 27.793971],
                [119.78982, 27.791033],
                [119.789738, 27.786791],
                [119.792325, 27.785126],
                [119.793396, 27.786728],
                [119.79646, 27.794976],
                [119.79791, 27.800961],
                [119.800382, 27.805768],
                [119.805621, 27.807732],
                [119.81264, 27.808281],
                [119.816907, 27.807826],
                [119.82012, 27.806098],
                [119.821224, 27.804386],
                [119.825211, 27.804653],
                [119.830088, 27.802988],
                [119.833944, 27.800349],
                [119.835542, 27.797081],
                [119.835624, 27.792777],
                [119.832346, 27.787184],
                [119.835888, 27.777899],
                [119.836597, 27.770671],
                [119.835954, 27.766617],
                [119.832049, 27.760017],
                [119.830715, 27.755962],
                [119.831225, 27.754139],
                [119.834109, 27.752804],
                [119.839315, 27.751829],
                [119.841935, 27.748953],
                [119.848888, 27.74625],
                [119.852216, 27.742227],
                [119.854984, 27.736678],
                [119.861987, 27.735232],
                [119.865249, 27.732481],
                [119.866287, 27.729086],
                [119.869747, 27.725864],
                [119.877145, 27.724025],
                [119.879913, 27.721572],
                [119.879715, 27.717218],
                [119.876156, 27.707816],
                [119.878348, 27.706338],
                [119.876914, 27.703414],
                [119.876403, 27.697895],
                [119.877623, 27.695568],
                [119.881577, 27.692203],
                [119.882945, 27.684215],
                [119.886586, 27.679513],
                [119.894198, 27.673348],
                [119.895549, 27.672797],
                [119.8998, 27.674512],
                [119.903342, 27.673662],
                [119.914629, 27.67923],
                [119.908516, 27.684608],
                [119.914744, 27.688807],
                [119.917759, 27.689514],
                [119.920165, 27.688948],
                [119.921697, 27.68714],
                [119.920857, 27.682453],
                [119.92341, 27.681651],
                [119.925272, 27.68758],
                [119.930331, 27.689892],
                [119.942754, 27.68206],
                [119.954765, 27.683224],
                [119.965227, 27.684875],
                [119.968325, 27.689656],
                [119.970022, 27.694876],
                [119.97307, 27.696496],
                [119.979825, 27.696134],
                [119.987998, 27.691448],
                [119.987372, 27.685064],
                [119.989678, 27.683083],
                [119.996417, 27.679922],
                [119.997735, 27.678522],
                [119.997126, 27.67643],
                [119.991227, 27.67459],
                [119.992628, 27.672278],
                [119.996664, 27.673033],
                [119.996615, 27.669039],
                [119.99823, 27.667387],
                [119.999333, 27.663141],
                [119.998823, 27.661159],
                [119.994704, 27.65364],
                [119.990667, 27.651123],
                [119.988476, 27.651186],
                [119.987503, 27.647946],
                [119.988953, 27.646294],
                [119.989365, 27.643447],
                [119.988031, 27.640159],
                [119.988937, 27.636399],
                [119.987602, 27.632041],
                [119.990107, 27.628564],
                [120.000306, 27.624552],
                [120.005232, 27.618761],
                [120.005924, 27.621405],
                [120.009005, 27.621546],
                [120.009137, 27.61969],
                [120.01146, 27.618604],
                [120.008511, 27.616448],
                [120.014624, 27.613868],
                [120.021, 27.608989],
                [120.017194, 27.603844],
                [120.015348, 27.599185],
                [120.014953, 27.59566],
                [120.016996, 27.593079],
                [120.023471, 27.591049],
                [120.026898, 27.589349],
                [120.029073, 27.586579],
                [120.032929, 27.58491],
                [120.038877, 27.586311],
                [120.04578, 27.587192],
                [120.049372, 27.584375],
                [120.048235, 27.578268],
                [120.046917, 27.575104],
                [120.048186, 27.573593],
                [120.060197, 27.566918],
                [120.06888, 27.564116],
                [120.067364, 27.560999],
                [120.069292, 27.557866],
                [120.068913, 27.553363],
                [120.069654, 27.551253],
                [120.066227, 27.546231],
                [120.067546, 27.543806],
                [120.072027, 27.541712],
                [120.0787, 27.540987],
                [120.086839, 27.54072],
                [120.090332, 27.539035],
                [120.094089, 27.536059],
                [120.096923, 27.531728],
                [120.098488, 27.528028],
                [120.104766, 27.523539],
                [120.109445, 27.522768],
                [120.111455, 27.521492],
                [120.111109, 27.517019],
                [120.11625, 27.517303],
                [120.122362, 27.51609],
                [120.127684, 27.518074],
                [120.131408, 27.518074],
                [120.136713, 27.515885],
                [120.135823, 27.511932],
                [120.131441, 27.509459],
                [120.131622, 27.50623],
                [120.143518, 27.507349],
                [120.147027, 27.504592],
                [120.146105, 27.49774],
                [120.158034, 27.498859],
                [120.160406, 27.494905],
                [120.166058, 27.490116],
                [120.168463, 27.486713],
                [120.169056, 27.482333],
                [120.16815, 27.479687],
                [120.168249, 27.475732],
                [120.17077, 27.473148],
                [120.173241, 27.472313],
                [120.176026, 27.478505],
                [120.180441, 27.479025],
                [120.189405, 27.475354],
                [120.190162, 27.474345],
                [120.196753, 27.472187],
                [120.198186, 27.47069],
                [120.198895, 27.462355],
                [120.200954, 27.458447],
                [120.203426, 27.457171],
                [120.206243, 27.462497],
                [120.210148, 27.463442],
                [120.215322, 27.460826],
                [120.216327, 27.45758],
                [120.220199, 27.45788],
                [120.227037, 27.455863],
                [120.231139, 27.455831],
                [120.240794, 27.452175],
                [120.244864, 27.447479],
                [120.248983, 27.439883],
                [120.246907, 27.434571],
                [120.238026, 27.429228],
                [120.229557, 27.426469],
                [120.226625, 27.422986],
                [120.223132, 27.422103],
                [120.221616, 27.42029],
                [120.219194, 27.421],
                [120.217118, 27.419833],
                [120.213213, 27.42029],
                [120.210165, 27.421946],
                [120.20621, 27.426469],
                [120.202388, 27.428361],
                [120.19672, 27.426911],
                [120.193145, 27.427352],
                [120.189701, 27.425004],
                [120.18176, 27.420748],
                [120.176899, 27.419959],
                [120.174543, 27.421741],
                [120.171314, 27.422639],
                [120.16726, 27.422418],
                [120.165613, 27.424799],
                [120.152102, 27.423679],
                [120.141837, 27.422166],
                [120.134522, 27.419833],
                [120.131424, 27.416696],
                [120.130535, 27.413386],
                [120.131836, 27.410123],
                [120.135362, 27.406891],
                [120.137191, 27.402319],
                [120.136104, 27.396816],
                [120.133006, 27.393458],
                [120.128887, 27.391629],
                [120.125641, 27.393095],
                [120.124109, 27.395697],
                [120.121604, 27.397415],
                [120.117436, 27.397542],
                [120.107171, 27.395586],
                [120.098389, 27.391818],
                [120.096247, 27.390352],
                [120.096857, 27.379629],
                [120.093792, 27.377721],
                [120.089558, 27.376964],
                [120.086312, 27.374946],
                [120.082078, 27.371035],
                [120.077036, 27.36919],
                [120.074449, 27.363843],
                [120.072093, 27.361225],
                [120.067694, 27.359616],
                [120.064168, 27.354964],
                [120.061169, 27.34744],
                [120.055518, 27.347093],
                [120.053607, 27.344632],
                [120.052288, 27.338669],
                [120.045154, 27.338511],
                [120.035697, 27.342455],
                [120.027574, 27.342266],
                [120.026289, 27.344206],
                [120.027195, 27.347519],
                [120.026602, 27.350799],
                [120.019484, 27.358749],
                [120.015958, 27.364758],
                [120.009598, 27.363938],
                [120.009104, 27.365783],
                [120.009516, 27.371618],
                [120.008033, 27.375198],
                [120.005216, 27.37668],
                [119.994209, 27.379188],
                [119.988673, 27.375987],
                [119.988344, 27.371366],
                [119.985642, 27.370309],
                [119.980369, 27.370924],
                [119.971439, 27.367818],
                [119.960285, 27.366098],
                [119.956577, 27.363386],
                [119.955193, 27.359948],
                [119.956446, 27.358307],
                [119.964025, 27.360484],
                [119.964931, 27.357771],
                [119.962888, 27.354459],
                [119.960186, 27.351872],
                [119.951321, 27.346761],
                [119.94913, 27.34367],
                [119.949147, 27.341493],
                [119.947021, 27.338795],
                [119.944583, 27.337802],
                [119.939063, 27.337518],
                [119.93791, 27.334599],
                [119.943347, 27.334252],
                [119.943578, 27.3331],
                [119.938733, 27.329866],
                [119.940661, 27.326143],
                [119.938882, 27.323777],
                [119.940546, 27.321962],
                [119.944171, 27.322057],
                [119.945934, 27.315809],
                [119.944912, 27.314184],
                [119.940941, 27.315604],
                [119.93351, 27.316535],
                [119.929902, 27.315588],
                [119.922092, 27.316472],
                [119.921005, 27.31431],
                [119.918616, 27.313079],
                [119.914942, 27.316976],
                [119.910098, 27.318318],
                [119.907626, 27.317513],
                [119.905665, 27.318318],
                [119.902107, 27.317087],
                [119.899339, 27.314452],
                [119.899058, 27.311501],
                [119.891331, 27.310791],
                [119.887624, 27.313316],
                [119.884839, 27.307367],
                [119.883373, 27.309071],
                [119.878974, 27.309608],
                [119.87815, 27.301828],
                [119.874888, 27.300961],
                [119.873388, 27.303217],
                [119.873306, 27.307257],
                [119.866946, 27.306089],
                [119.865101, 27.306452],
                [119.865232, 27.30934],
                [119.870736, 27.31188],
                [119.871675, 27.315209],
                [119.864524, 27.315351],
                [119.859153, 27.317008],
                [119.855759, 27.31472],
                [119.853847, 27.317292],
                [119.850766, 27.319469],
                [119.848311, 27.322893],
                [119.846219, 27.324061],
                [119.843022, 27.322483],
                [119.835081, 27.312953],
                [119.835064, 27.311785],
                [119.839002, 27.308093],
                [119.844835, 27.306641],
                [119.84322, 27.300645],
                [119.839694, 27.299083],
                [119.834735, 27.301339],
                [119.830533, 27.299919],
                [119.826661, 27.300187],
                [119.826661, 27.301671],
                [119.82307, 27.301923],
                [119.821966, 27.297489],
                [119.819527, 27.296037],
                [119.814848, 27.297868],
                [119.812656, 27.299572],
                [119.809888, 27.304495],
                [119.802293, 27.307998],
                [119.795752, 27.310428],
                [119.792885, 27.309829],
                [119.791616, 27.304622],
                [119.790463, 27.302602],
                [119.780017, 27.303754],
                [119.774596, 27.306231],
                [119.770559, 27.305679],
                [119.7685, 27.308282],
                [119.770955, 27.311628],
                [119.770905, 27.318191],
                [119.769785, 27.324392],
                [119.770774, 27.326553],
                [119.773097, 27.326806],
                [119.776524, 27.323209],
                [119.777974, 27.320179],
                [119.779391, 27.320211],
                [119.784976, 27.323587],
                [119.78519, 27.32638],
                [119.781483, 27.330497],
                [119.777101, 27.331618],
                [119.773196, 27.331428],
                [119.768385, 27.335735],
                [119.76761, 27.338496],
                [119.769357, 27.342771],
                [119.769142, 27.345421],
                [119.766276, 27.347629],
                [119.759685, 27.346825],
                [119.754627, 27.349932],
                [119.751035, 27.35009],
                [119.749074, 27.353292],
                [119.747608, 27.359632],
                [119.74049, 27.362203],
                [119.740474, 27.364222],
                [119.745301, 27.369678],
                [119.750458, 27.373227],
                [119.750442, 27.376759],
                [119.747493, 27.378273],
                [119.744494, 27.377469],
                [119.741446, 27.375435],
                [119.74021, 27.376507],
                [119.741199, 27.380749],
                [119.739584, 27.386804],
                [119.735745, 27.387845],
                [119.732532, 27.3898],
                [119.731082, 27.39248],
                [119.731725, 27.394719],
                [119.727737, 27.396343],
                [119.724903, 27.401231],
                [119.722086, 27.402729],
                [119.718362, 27.403359],
                [119.71304, 27.402839],
                [119.710157, 27.403911],
                [119.709383, 27.406055],
                [119.710371, 27.410974],
                [119.707224, 27.413118],
                [119.704423, 27.417343],
                [119.705445, 27.418556],
                [119.704687, 27.424688],
                [119.702957, 27.425965],
                [119.6992, 27.425729],
                [119.694702, 27.429748],
                [119.689512, 27.43082],
                [119.687123, 27.436037],
                [119.685459, 27.438149],
                [119.688244, 27.440623],
                [119.693384, 27.438764],
                [119.695641, 27.440529],
                [119.695312, 27.442704],
                [119.698393, 27.44357],
                [119.703418, 27.44729],
                [119.703748, 27.452853],
                [119.70561, 27.455012],
                [119.704011, 27.458526],
                [119.709926, 27.462465],
                [119.706714, 27.47132],
                [119.709284, 27.475228],
                [119.707669, 27.478725],
                [119.710305, 27.480238],
                [119.709877, 27.482743],
                [119.706895, 27.4829],
                [119.706598, 27.486477],
                [119.71052, 27.489202],
                [119.709646, 27.496795],
                [119.704061, 27.498354],
                [119.702875, 27.500276],
                [119.704028, 27.507238],
                [119.707817, 27.510341],
                [119.708559, 27.514279],
                [119.705643, 27.516468],
                [119.702183, 27.517051],
                [119.700238, 27.521571],
                [119.700172, 27.525287],
                [119.695543, 27.5285],
                [119.689512, 27.534043],
                [119.690583, 27.53724],
                [119.687436, 27.538011],
                [119.684652, 27.541145],
                [119.684059, 27.537744],
                [119.682576, 27.536941],
                [119.679182, 27.537917],
                [119.670548, 27.536578],
                [119.66717, 27.533586],
                [119.663908, 27.537791],
                [119.659328, 27.539696],
                [119.661618, 27.544845],
                [119.664452, 27.54812],
                [119.667137, 27.554544],
                [119.668867, 27.556496],
                [119.672014, 27.556402],
                [119.671619, 27.560401],
                [119.672954, 27.566037],
                [119.677386, 27.570381],
                [119.675063, 27.574396],
                [119.672805, 27.575025],
                [119.662886, 27.575796],
                [119.658454, 27.57704],
                [119.653165, 27.577197],
                [119.649359, 27.578205],
                [119.645438, 27.578205],
                [119.640149, 27.581684],
                [119.634053, 27.580235],
                [119.630889, 27.582691],
                [119.630362, 27.586358],
                [119.630346, 27.593913],
                [119.628715, 27.597973],
                [119.628517, 27.601703],
                [119.630115, 27.60537],
                [119.629983, 27.607825],
                [119.627265, 27.610862],
                [119.62532, 27.614812],
                [119.626474, 27.620476],
                [119.629357, 27.622506],
                [119.631796, 27.623025],
                [119.636557, 27.626062],
                [119.639902, 27.624819],
                [119.641121, 27.625385],
                [119.641302, 27.628721],
                [119.64323, 27.63245],
                [119.646377, 27.634401],
                [119.647514, 27.640536],
                [119.644103, 27.644076],
                [119.640479, 27.64933],
                [119.639638, 27.652665],
                [119.641632, 27.655465],
                [119.644054, 27.660577],
                [119.644054, 27.663502],
                [119.640561, 27.666066],
                [119.637085, 27.666868],
                [119.635569, 27.668394],
                [119.640989, 27.671288],
                [119.643, 27.673175],
                [119.645932, 27.681148],
                [119.646954, 27.68574],
                [119.649837, 27.68791],
                [119.654088, 27.68942],
                [119.656131, 27.69343],
                [119.650925, 27.695552],
                [119.649359, 27.700222],
                [119.650826, 27.706873],
                [119.647217, 27.710222],
                [119.650727, 27.717139],
                [119.65712, 27.717674],
                [119.660399, 27.719513],
                [119.663298, 27.720047],
                [119.666001, 27.721871],
                [119.66689, 27.724811],
                [119.669592, 27.725785],
                [119.673201, 27.730202],
                [119.677122, 27.731711],
                [119.678605, 27.734006],
                [119.678852, 27.740136],
                [119.680187, 27.744207],
                [119.68541, 27.747947],
                [119.696993, 27.753652],
                [119.704028, 27.75579],
                [119.705758, 27.754674],
                [119.709943, 27.755522],
                [119.715215, 27.757738],
                [119.71945, 27.760441],
                [119.723767, 27.766837],
                [119.725876, 27.767717],
                [119.734822, 27.766633],
                [119.736519, 27.771252],
                [119.739485, 27.774803],
                [119.748415, 27.782675],
                [119.75405, 27.790483],
                [119.754544, 27.792368],
                [119.751661, 27.794803],
                [119.753243, 27.799123],
                [119.756209, 27.79562],
                [119.758037, 27.798369],
                [119.763573, 27.799343],
                [119.768137, 27.805297],
                [119.770329, 27.805925],
                [119.772207, 27.802925],
                [119.77163, 27.798291],
                [119.77308, 27.794096],
                [119.778402, 27.794254],
                [119.781846, 27.793704],
                [119.785059, 27.795777],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330381,
          name: "瑞安市",
          center: [120.646171, 27.779321],
          centroid: [120.464981, 27.822065],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 9,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.702115, 27.671697],
                [120.690022, 27.668582],
                [120.673875, 27.678805],
                [120.669344, 27.682139],
                [120.652275, 27.69126],
                [120.651896, 27.691983],
                [120.644547, 27.695521],
                [120.63878, 27.696747],
                [120.630575, 27.696653],
                [120.631086, 27.691385],
                [120.630526, 27.690127],
                [120.625797, 27.691228],
                [120.623408, 27.693571],
                [120.618366, 27.694263],
                [120.617081, 27.693587],
                [120.611397, 27.6942],
                [120.607937, 27.696166],
                [120.608464, 27.69909],
                [120.60596, 27.700301],
                [120.60848, 27.703037],
                [120.608217, 27.70953],
                [120.611875, 27.713445],
                [120.61171, 27.71585],
                [120.613885, 27.716762],
                [120.617065, 27.721698],
                [120.616636, 27.724889],
                [120.614016, 27.729621],
                [120.604081, 27.740938],
                [120.603093, 27.742745],
                [120.599517, 27.743861],
                [120.593586, 27.752002],
                [120.588247, 27.753621],
                [120.583041, 27.753087],
                [120.573814, 27.748372],
                [120.568492, 27.750022],
                [120.564637, 27.754092],
                [120.562347, 27.759153],
                [120.560304, 27.760944],
                [120.543349, 27.761573],
                [120.540219, 27.758587],
                [120.538472, 27.754658],
                [120.536924, 27.753432],
                [120.530415, 27.752049],
                [120.52829, 27.748938],
                [120.526972, 27.745291],
                [120.51931, 27.737244],
                [120.520711, 27.732371],
                [120.514466, 27.730297],
                [120.513675, 27.728174],
                [120.515076, 27.723867],
                [120.51127, 27.720519],
                [120.503872, 27.718271],
                [120.498105, 27.717328],
                [120.495156, 27.717878],
                [120.49219, 27.716746],
                [120.486901, 27.710992],
                [120.485995, 27.708587],
                [120.488318, 27.705033],
                [120.489439, 27.700537],
                [120.488351, 27.693791],
                [120.488401, 27.688634],
                [120.486572, 27.683428],
                [120.483458, 27.677091],
                [120.474989, 27.67566],
                [120.470211, 27.67069],
                [120.463192, 27.665689],
                [120.458134, 27.666585],
                [120.45474, 27.670894],
                [120.449747, 27.675487],
                [120.448429, 27.679214],
                [120.444837, 27.680614],
                [120.445019, 27.68173],
                [120.439993, 27.68541],
                [120.431014, 27.689483],
                [120.423566, 27.691197],
                [120.421276, 27.687454],
                [120.418591, 27.688193],
                [120.413796, 27.690756],
                [120.409957, 27.689624],
                [120.406612, 27.685724],
                [120.412692, 27.681243],
                [120.410715, 27.678679],
                [120.409561, 27.680079],
                [120.403169, 27.683177],
                [120.397616, 27.689986],
                [120.390152, 27.696905],
                [120.388422, 27.700269],
                [120.385819, 27.702392],
                [120.379278, 27.706323],
                [120.377416, 27.708115],
                [120.383858, 27.71236],
                [120.385209, 27.715771],
                [120.384468, 27.718522],
                [120.380003, 27.720912],
                [120.376988, 27.720975],
                [120.354563, 27.725062],
                [120.351021, 27.728112],
                [120.345221, 27.729668],
                [120.344002, 27.730925],
                [120.342668, 27.735106],
                [120.33756, 27.7366],
                [120.334545, 27.738769],
                [120.332946, 27.743311],
                [120.329536, 27.747319],
                [120.327015, 27.747146],
                [120.326175, 27.74515],
                [120.328366, 27.741472],
                [120.329173, 27.73803],
                [120.324955, 27.73432],
                [120.322171, 27.733739],
                [120.316437, 27.733912],
                [120.314476, 27.732104],
                [120.313817, 27.728332],
                [120.3139, 27.721006],
                [120.310802, 27.718334],
                [120.307837, 27.717076],
                [120.29861, 27.724575],
                [120.293271, 27.731271],
                [120.289745, 27.734258],
                [120.284737, 27.736348],
                [120.281293, 27.736065],
                [120.275807, 27.728929],
                [120.261176, 27.722044],
                [120.256496, 27.721729],
                [120.253514, 27.722547],
                [120.252904, 27.727797],
                [120.251833, 27.730422],
                [120.256381, 27.736002],
                [120.256875, 27.739476],
                [120.254667, 27.740781],
                [120.243348, 27.744301],
                [120.238867, 27.747602],
                [120.236362, 27.751169],
                [120.232012, 27.754894],
                [120.224153, 27.758351],
                [120.223329, 27.762767],
                [120.217629, 27.768047],
                [120.212504, 27.773735],
                [120.210544, 27.778401],
                [120.208649, 27.779548],
                [120.20023, 27.779737],
                [120.197857, 27.781465],
                [120.196967, 27.78588],
                [120.197939, 27.789651],
                [120.196852, 27.791269],
                [120.188004, 27.794741],
                [120.181133, 27.799862],
                [120.175087, 27.800506],
                [120.174839, 27.804182],
                [120.176438, 27.806177],
                [120.185401, 27.812727],
                [120.186488, 27.815821],
                [120.189915, 27.822229],
                [120.190525, 27.824946],
                [120.194726, 27.83027],
                [120.195995, 27.833521],
                [120.198121, 27.844544],
                [120.198005, 27.846962],
                [120.191612, 27.862852],
                [120.19298, 27.870183],
                [120.198697, 27.871141],
                [120.210676, 27.877734],
                [120.212488, 27.881172],
                [120.2143, 27.888565],
                [120.216492, 27.889993],
                [120.224862, 27.888533],
                [120.22763, 27.888816],
                [120.228684, 27.893226],
                [120.223873, 27.903192],
                [120.220759, 27.906002],
                [120.224549, 27.91413],
                [120.229673, 27.917269],
                [120.229261, 27.922023],
                [120.227712, 27.924361],
                [120.227761, 27.929367],
                [120.229953, 27.931344],
                [120.234039, 27.932803],
                [120.238619, 27.933415],
                [120.241602, 27.934952],
                [120.242129, 27.938969],
                [120.245605, 27.943424],
                [120.246462, 27.94744],
                [120.245951, 27.952303],
                [120.24778, 27.958029],
                [120.248983, 27.964005],
                [120.257452, 27.967346],
                [120.260599, 27.965307],
                [120.258045, 27.961213],
                [120.26078, 27.958766],
                [120.265575, 27.957134],
                [120.270781, 27.957385],
                [120.276531, 27.959613],
                [120.281046, 27.963534],
                [120.290059, 27.972443],
                [120.295183, 27.97707],
                [120.302317, 27.977352],
                [120.307309, 27.978418],
                [120.311313, 27.977509],
                [120.319271, 27.979218],
                [120.324626, 27.978999],
                [120.326949, 27.976583],
                [120.328152, 27.967926],
                [120.328926, 27.966671],
                [120.333062, 27.968444],
                [120.336061, 27.970686],
                [120.3413, 27.976583],
                [120.343722, 27.98099],
                [120.350659, 27.984127],
                [120.349126, 27.987702],
                [120.358798, 27.983562],
                [120.363708, 27.983844],
                [120.372358, 27.981947],
                [120.377268, 27.981696],
                [120.38198, 27.979516],
                [120.386626, 27.978591],
                [120.398605, 27.971408],
                [120.39961, 27.966593],
                [120.403399, 27.966012],
                [120.408177, 27.962405],
                [120.410649, 27.958091],
                [120.412626, 27.956538],
                [120.415295, 27.956585],
                [120.414125, 27.962248],
                [120.417058, 27.966091],
                [120.425741, 27.966389],
                [120.428509, 27.967063],
                [120.431805, 27.966655],
                [120.433716, 27.963487],
                [120.437489, 27.96893],
                [120.440521, 27.971596],
                [120.446617, 27.970906],
                [120.448133, 27.971502],
                [120.451741, 27.976191],
                [120.454723, 27.975705],
                [120.461248, 27.970718],
                [120.467146, 27.969102],
                [120.467311, 27.965307],
                [120.468761, 27.964805],
                [120.482848, 27.964365],
                [120.487923, 27.962624],
                [120.493393, 27.96322],
                [120.494612, 27.962577],
                [120.495832, 27.955911],
                [120.500659, 27.954311],
                [120.501582, 27.952727],
                [120.498979, 27.949919],
                [120.499407, 27.947173],
                [120.504284, 27.944459],
                [120.503147, 27.942985],
                [120.498287, 27.941322],
                [120.497628, 27.938153],
                [120.496161, 27.936929],
                [120.486012, 27.933744],
                [120.485138, 27.932803],
                [120.487725, 27.930073],
                [120.498089, 27.930763],
                [120.503279, 27.930041],
                [120.509771, 27.925115],
                [120.513708, 27.922792],
                [120.513033, 27.91719],
                [120.514746, 27.912075],
                [120.518849, 27.912263],
                [120.52941, 27.911745],
                [120.537764, 27.909234],
                [120.541059, 27.907696],
                [120.541998, 27.906002],
                [120.540845, 27.904008],
                [120.536314, 27.901419],
                [120.537995, 27.898939],
                [120.542031, 27.897417],
                [120.548342, 27.89602],
                [120.55228, 27.893556],
                [120.553697, 27.889915],
                [120.557058, 27.891359],
                [120.561819, 27.897746],
                [120.565411, 27.901184],
                [120.567421, 27.902],
                [120.572727, 27.901136],
                [120.575149, 27.899457],
                [120.5779, 27.895282],
                [120.583206, 27.897621],
                [120.588972, 27.898374],
                [120.593487, 27.902816],
                [120.597309, 27.904464],
                [120.598825, 27.902894],
                [120.601659, 27.896083],
                [120.606503, 27.892473],
                [120.612072, 27.889271],
                [120.614494, 27.887058],
                [120.614395, 27.884955],
                [120.611232, 27.880136],
                [120.615582, 27.878629],
                [120.619668, 27.875536],
                [120.622271, 27.871879],
                [120.622502, 27.868315],
                [120.618366, 27.85979],
                [120.61489, 27.855504],
                [120.614049, 27.853478],
                [120.614906, 27.851327],
                [120.616883, 27.851375],
                [120.627675, 27.85552],
                [120.63654, 27.856995],
                [120.640543, 27.854876],
                [120.645585, 27.850574],
                [120.653032, 27.848956],
                [120.659425, 27.850841],
                [120.668339, 27.850998],
                [120.674781, 27.850684],
                [120.679691, 27.852536],
                [120.684074, 27.856132],
                [120.68526, 27.858126],
                [120.684502, 27.862491],
                [120.685392, 27.865222],
                [120.687863, 27.867028],
                [120.697815, 27.868362],
                [120.702017, 27.869712],
                [120.706959, 27.873197],
                [120.716911, 27.875615],
                [120.723073, 27.875348],
                [120.723452, 27.874218],
                [120.716845, 27.871518],
                [120.718394, 27.869037],
                [120.730125, 27.868535],
                [120.736848, 27.869398],
                [120.747112, 27.866808],
                [120.752253, 27.86103],
                [120.754345, 27.853808],
                [120.756817, 27.852819],
                [120.758382, 27.849428],
                [120.758283, 27.846978],
                [120.754313, 27.845675],
                [120.751742, 27.842691],
                [120.750572, 27.837211],
                [120.748529, 27.837431],
                [120.747244, 27.833976],
                [120.747343, 27.826595],
                [120.748266, 27.824632],
                [120.750918, 27.823815],
                [120.750721, 27.822543],
                [120.754065, 27.819936],
                [120.753406, 27.818192],
                [120.763688, 27.808753],
                [120.769471, 27.802909],
                [120.773326, 27.799626],
                [120.77845, 27.797113],
                [120.798733, 27.762138],
                [120.777676, 27.774725],
                [120.771662, 27.734462],
                [120.767889, 27.726304],
                [120.761101, 27.717705],
                [120.739649, 27.702156],
                [120.71879, 27.689231],
                [120.70897, 27.682564],
                [120.702115, 27.671697],
              ],
            ],
            [
              [
                [120.971751, 27.649849],
                [120.96778, 27.65806],
                [120.967681, 27.66215],
                [120.969148, 27.664131],
                [120.975919, 27.662952],
                [120.976348, 27.664446],
                [120.980187, 27.666396],
                [120.978243, 27.668991],
                [120.979264, 27.67025],
                [120.983498, 27.668394],
                [120.985838, 27.665264],
                [120.985673, 27.663203],
                [120.988837, 27.662433],
                [120.987206, 27.659885],
                [120.984635, 27.658359],
                [120.98653, 27.655103],
                [120.984075, 27.651988],
                [120.976875, 27.647269],
                [120.975985, 27.645775],
                [120.971619, 27.644013],
                [120.968966, 27.645177],
                [120.971652, 27.647332],
                [120.971751, 27.649849],
              ],
            ],
            [
              [
                [121.199948, 27.624945],
                [121.201398, 27.623151],
                [121.203655, 27.625842],
                [121.20588, 27.626046],
                [121.204397, 27.630169],
                [121.208351, 27.630405],
                [121.207758, 27.632198],
                [121.213591, 27.633331],
                [121.214678, 27.631789],
                [121.217364, 27.633079],
                [121.220692, 27.632497],
                [121.220824, 27.630294],
                [121.216655, 27.62644],
                [121.215106, 27.623623],
                [121.208994, 27.618525],
                [121.206193, 27.617786],
                [121.202766, 27.615504],
                [121.198317, 27.614654],
                [121.198614, 27.616983],
                [121.194083, 27.620351],
                [121.191891, 27.61906],
                [121.191677, 27.621924],
                [121.194577, 27.623246],
                [121.196801, 27.622884],
                [121.199948, 27.624945],
              ],
            ],
            [
              [
                [120.903407, 27.69387],
                [120.901809, 27.695552],
                [120.903638, 27.696889],
                [120.90489, 27.699829],
                [120.907674, 27.700379],
                [120.912222, 27.69843],
                [120.910657, 27.697974],
                [120.909717, 27.695159],
                [120.911118, 27.692832],
                [120.914298, 27.691951],
                [120.911925, 27.689184],
                [120.908004, 27.692518],
                [120.903407, 27.69387],
              ],
            ],
            [
              [
                [121.21743, 27.613695],
                [121.220181, 27.617282],
                [121.224564, 27.617896],
                [121.228666, 27.616432],
                [121.228172, 27.614576],
                [121.225272, 27.612577],
                [121.225042, 27.609902],
                [121.226656, 27.611145],
                [121.227118, 27.608927],
                [121.224959, 27.607038],
                [121.222043, 27.606771],
                [121.220428, 27.608864],
                [121.221845, 27.6105],
                [121.221087, 27.613348],
                [121.2171, 27.611381],
                [121.21743, 27.613695],
              ],
            ],
            [
              [
                [121.055072, 27.633646],
                [121.055731, 27.634637],
                [121.060081, 27.63525],
                [121.061975, 27.63717],
                [121.064546, 27.637044],
                [121.063458, 27.633016],
                [121.059224, 27.631034],
                [121.057807, 27.632733],
                [121.055072, 27.633646],
              ],
            ],
            [
              [
                [121.168824, 27.63042],
                [121.173751, 27.631758],
                [121.175415, 27.629649],
                [121.177639, 27.629114],
                [121.176354, 27.626802],
                [121.172861, 27.626786],
                [121.172581, 27.624032],
                [121.168231, 27.623545],
                [121.166023, 27.625747],
                [121.166666, 27.629618],
                [121.168034, 27.628469],
                [121.168824, 27.63042],
              ],
            ],
            [
              [
                [120.826232, 27.686196],
                [120.830071, 27.685866],
                [120.833102, 27.687376],
                [120.834355, 27.68596],
                [120.83732, 27.685316],
                [120.839709, 27.68206],
                [120.842164, 27.680818],
                [120.842758, 27.67555],
                [120.838227, 27.674354],
                [120.832476, 27.676855],
                [120.831751, 27.682281],
                [120.829082, 27.684183],
                [120.826677, 27.684089],
                [120.826232, 27.686196],
              ],
            ],
            [
              [
                [120.936837, 27.639026],
                [120.938699, 27.63975],
                [120.941434, 27.638192],
                [120.940841, 27.636399],
                [120.937447, 27.636147],
                [120.936837, 27.639026],
              ],
            ],
            [
              [
                [120.82493, 27.6462],
                [120.83162, 27.649629],
                [120.834799, 27.649912],
                [120.838671, 27.647836],
                [120.836085, 27.645492],
                [120.831389, 27.64491],
                [120.829313, 27.641889],
                [120.829511, 27.640347],
                [120.826182, 27.639341],
                [120.825721, 27.637028],
                [120.821981, 27.634857],
                [120.821569, 27.636855],
                [120.823447, 27.640222],
                [120.822854, 27.645366],
                [120.82493, 27.6462],
              ],
            ],
            [
              [
                [121.204347, 27.609745],
                [121.207923, 27.615378],
                [121.208928, 27.618179],
                [121.213376, 27.619705],
                [121.212915, 27.616606],
                [121.214332, 27.618808],
                [121.216919, 27.618919],
                [121.210032, 27.608486],
                [121.205847, 27.607022],
                [121.204347, 27.609745],
              ],
            ],
            [
              [
                [120.93949, 27.66215],
                [120.942093, 27.663628],
                [120.945207, 27.662448],
                [120.947975, 27.663203],
                [120.94959, 27.659633],
                [120.945586, 27.658752],
                [120.938337, 27.661253],
                [120.93949, 27.66215],
              ],
            ],
            [
              [
                [120.932735, 27.644013],
                [120.936294, 27.647191],
                [120.940149, 27.646373],
                [120.940874, 27.644737],
                [120.938205, 27.640646],
                [120.93369, 27.640851],
                [120.934976, 27.641685],
                [120.932735, 27.644013],
              ],
            ],
            [
              [
                [120.896536, 27.711432],
                [120.90199, 27.711102],
                [120.902484, 27.710253],
                [120.899469, 27.706055],
                [120.892005, 27.703555],
                [120.887392, 27.704515],
                [120.887458, 27.707848],
                [120.888628, 27.709153],
                [120.893884, 27.709514],
                [120.896536, 27.711432],
              ],
            ],
            [
              [
                [120.839825, 27.671382],
                [120.839924, 27.674213],
                [120.844932, 27.669919],
                [120.846531, 27.667403],
                [120.842444, 27.665689],
                [120.841654, 27.668928],
                [120.839825, 27.671382],
              ],
            ],
            [
              [
                [120.916275, 27.68519],
                [120.918137, 27.687973],
                [120.921778, 27.688445],
                [120.924991, 27.69019],
                [120.92476, 27.68758],
                [120.922404, 27.686794],
                [120.921498, 27.684561],
                [120.916275, 27.68519],
              ],
            ],
            [
              [
                [120.931054, 27.655134],
                [120.932916, 27.657101],
                [120.935684, 27.655512],
                [120.933246, 27.653483],
                [120.931054, 27.655134],
              ],
            ],
            [
              [
                [121.190754, 27.651186],
                [121.193605, 27.654222],
                [121.197312, 27.654961],
                [121.195977, 27.651894],
                [121.193111, 27.650557],
                [121.190754, 27.651186],
              ],
            ],
            [
              [
                [121.132873, 27.624363],
                [121.136069, 27.625637],
                [121.136036, 27.620476],
                [121.133664, 27.618714],
                [121.130846, 27.618997],
                [121.132873, 27.624363],
              ],
            ],
            [
              [
                [120.918582, 27.680928],
                [120.923772, 27.682501],
                [120.9228, 27.67934],
                [120.917857, 27.679513],
                [120.918582, 27.680928],
              ],
            ],
            [
              [
                [120.81076, 27.68964],
                [120.813084, 27.692926],
                [120.81689, 27.694782],
                [120.821141, 27.695364],
                [120.823035, 27.694452],
                [120.822393, 27.69203],
                [120.819526, 27.691464],
                [120.815193, 27.687753],
                [120.81193, 27.687769],
                [120.81076, 27.68964],
              ],
            ],
            [
              [
                [121.195813, 27.661882],
                [121.19372, 27.663974],
                [121.194247, 27.665374],
                [121.199124, 27.665641],
                [121.20036, 27.664131],
                [121.199553, 27.662118],
                [121.195813, 27.661882],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330382,
          name: "乐清市",
          center: [120.967147, 28.116083],
          centroid: [121.018675, 28.24076],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 10,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.785601, 28.011849],
                [120.788419, 28.014906],
                [120.790874, 28.014765],
                [120.79402, 28.016223],
                [120.794614, 28.018089],
                [120.796723, 28.017697],
                [120.798057, 28.019845],
                [120.797102, 28.025551],
                [120.798996, 28.028717],
                [120.802242, 28.030614],
                [120.800611, 28.033545],
                [120.803264, 28.034141],
                [120.80272, 28.036492],
                [120.805373, 28.038091],
                [120.805653, 28.04016],
                [120.804104, 28.041931],
                [120.807053, 28.043405],
                [120.807399, 28.04585],
                [120.812013, 28.051523],
                [120.813364, 28.056178],
                [120.812309, 28.058999],
                [120.812672, 28.063089],
                [120.809146, 28.067335],
                [120.810826, 28.069028],
                [120.810695, 28.074105],
                [120.808635, 28.077301],
                [120.808355, 28.081939],
                [120.810909, 28.083286],
                [120.812243, 28.08902],
                [120.814319, 28.094833],
                [120.821091, 28.109683],
                [120.824205, 28.114445],
                [120.828851, 28.119159],
                [120.831323, 28.122401],
                [120.833696, 28.130404],
                [120.835656, 28.132659],
                [120.840879, 28.143057],
                [120.844553, 28.148538],
                [120.852446, 28.15657],
                [120.856367, 28.16122],
                [120.865511, 28.173478],
                [120.867554, 28.177048],
                [120.86678, 28.184467],
                [120.86767, 28.186158],
                [120.875232, 28.19054],
                [120.87627, 28.190556],
                [120.878231, 28.19414],
                [120.87917, 28.200182],
                [120.882927, 28.203139],
                [120.895647, 28.207068],
                [120.901133, 28.209149],
                [120.900441, 28.215049],
                [120.898217, 28.226831],
                [120.891808, 28.230774],
                [120.884937, 28.230586],
                [120.882152, 28.231228],
                [120.882531, 28.240568],
                [120.885481, 28.243431],
                [120.889715, 28.243603],
                [120.895993, 28.24742],
                [120.900458, 28.248734],
                [120.903835, 28.254804],
                [120.903835, 28.263532],
                [120.902039, 28.272557],
                [120.900919, 28.276201],
                [120.901512, 28.279641],
                [120.900804, 28.289009],
                [120.898349, 28.300674],
                [120.896174, 28.307366],
                [120.888677, 28.315261],
                [120.887194, 28.321123],
                [120.888117, 28.32686],
                [120.886585, 28.330221],
                [120.887062, 28.3323],
                [120.893752, 28.338521],
                [120.897064, 28.340694],
                [120.901858, 28.3411],
                [120.906719, 28.342397],
                [120.913128, 28.341178],
                [120.919587, 28.33738],
                [120.919801, 28.332128],
                [120.917791, 28.330596],
                [120.918648, 28.326501],
                [120.920295, 28.326204],
                [120.926639, 28.328908],
                [120.934778, 28.329815],
                [120.939045, 28.331784],
                [120.941846, 28.336317],
                [120.946789, 28.337959],
                [120.952951, 28.33688],
                [120.956032, 28.338099],
                [120.959311, 28.343538],
                [120.96544, 28.351056],
                [120.967121, 28.355291],
                [120.967302, 28.357917],
                [120.957729, 28.360901],
                [120.960843, 28.368183],
                [120.961239, 28.370746],
                [120.968423, 28.372684],
                [120.972608, 28.375715],
                [120.982543, 28.380824],
                [120.98251, 28.383277],
                [120.979264, 28.385964],
                [120.984322, 28.390979],
                [120.983548, 28.393401],
                [120.986596, 28.394338],
                [120.987189, 28.395963],
                [120.983993, 28.400587],
                [120.973151, 28.39779],
                [120.971372, 28.397837],
                [120.966775, 28.40054],
                [120.962985, 28.400774],
                [120.960465, 28.402289],
                [120.960184, 28.40735],
                [120.967121, 28.416941],
                [120.966973, 28.418393],
                [120.962475, 28.419065],
                [120.957944, 28.421579],
                [120.959295, 28.424547],
                [120.962508, 28.427858],
                [120.962969, 28.431746],
                [120.965638, 28.433448],
                [120.965539, 28.435088],
                [120.968241, 28.436165],
                [120.974156, 28.436353],
                [120.977864, 28.437883],
                [120.984125, 28.442974],
                [120.984882, 28.444894],
                [120.982263, 28.448595],
                [120.979725, 28.455824],
                [120.980236, 28.459758],
                [120.979083, 28.462678],
                [120.973547, 28.462694],
                [120.970878, 28.4621],
                [120.970054, 28.463178],
                [120.974008, 28.470515],
                [120.973843, 28.472201],
                [120.970153, 28.475058],
                [120.970136, 28.476057],
                [120.973926, 28.477883],
                [120.971504, 28.480209],
                [120.967006, 28.48049],
                [120.965572, 28.482972],
                [120.967269, 28.482503],
                [120.974255, 28.483362],
                [120.977583, 28.481161],
                [120.984158, 28.480849],
                [120.987041, 28.481738],
                [120.989661, 28.480084],
                [120.991786, 28.477024],
                [120.9949, 28.476416],
                [120.99518, 28.473356],
                [121.000156, 28.472466],
                [121.002216, 28.472935],
                [121.002973, 28.47871],
                [121.005165, 28.479538],
                [121.011607, 28.477727],
                [121.015792, 28.479553],
                [121.017687, 28.478617],
                [121.020241, 28.472856],
                [121.025447, 28.472747],
                [121.028166, 28.477867],
                [121.031214, 28.479397],
                [121.032516, 28.478148],
                [121.036124, 28.481707],
                [121.047081, 28.478492],
                [121.049882, 28.478242],
                [121.062222, 28.480256],
                [121.070411, 28.480334],
                [121.075848, 28.482831],
                [121.076886, 28.484095],
                [121.076096, 28.48895],
                [121.076837, 28.492196],
                [121.079061, 28.495864],
                [121.082109, 28.497393],
                [121.086756, 28.503683],
                [121.088716, 28.509862],
                [121.088156, 28.517259],
                [121.093363, 28.524358],
                [121.100332, 28.529211],
                [121.102309, 28.533735],
                [121.104814, 28.537292],
                [121.107368, 28.538291],
                [121.110218, 28.536731],
                [121.113398, 28.53709],
                [121.12134, 28.532503],
                [121.129874, 28.521706],
                [121.139595, 28.522611],
                [121.143764, 28.52478],
                [121.14556, 28.523937],
                [121.145527, 28.519974],
                [121.14874, 28.519724],
                [121.151096, 28.521503],
                [121.155017, 28.521472],
                [121.156731, 28.520473],
                [121.158263, 28.51587],
                [121.163189, 28.513498],
                [121.167704, 28.50877],
                [121.169599, 28.505914],
                [121.172614, 28.505384],
                [121.172532, 28.499703],
                [121.170851, 28.497112],
                [121.173273, 28.494131],
                [121.175118, 28.493398],
                [121.179616, 28.483939],
                [121.180967, 28.478351],
                [121.183604, 28.477555],
                [121.184658, 28.474652],
                [121.188629, 28.475198],
                [121.191446, 28.473637],
                [121.195187, 28.473013],
                [121.194626, 28.467096],
                [121.202173, 28.461429],
                [121.205797, 28.461039],
                [121.209109, 28.458837],
                [121.20611, 28.455996],
                [121.209027, 28.450703],
                [121.209587, 28.4463],
                [121.211531, 28.445144],
                [121.211762, 28.44224],
                [121.214398, 28.438367],
                [121.219176, 28.438149],
                [121.221598, 28.438805],
                [121.225536, 28.437868],
                [121.226821, 28.435541],
                [121.224449, 28.433042],
                [121.221549, 28.427608],
                [121.221104, 28.424156],
                [121.222817, 28.422032],
                [121.228288, 28.423735],
                [121.232538, 28.423594],
                [121.23618, 28.422688],
                [121.239772, 28.420642],
                [121.242391, 28.417643],
                [121.248718, 28.413692],
                [121.252343, 28.412802],
                [121.257714, 28.408116],
                [121.25722, 28.406772],
                [121.251256, 28.405273],
                [121.250465, 28.403664],
                [121.251025, 28.399274],
                [121.246626, 28.390589],
                [121.246131, 28.385777],
                [121.238717, 28.384027],
                [121.235965, 28.384605],
                [121.227579, 28.384808],
                [121.22631, 28.382886],
                [121.220527, 28.378855],
                [121.220758, 28.376777],
                [121.222784, 28.375809],
                [121.222059, 28.372887],
                [121.216408, 28.371387],
                [121.211992, 28.370965],
                [121.209554, 28.373355],
                [121.20817, 28.37009],
                [121.204875, 28.368433],
                [121.208434, 28.362667],
                [121.212783, 28.359792],
                [121.214316, 28.35801],
                [121.215881, 28.351494],
                [121.215733, 28.349181],
                [121.213673, 28.345648],
                [121.209455, 28.329393],
                [121.210196, 28.326126],
                [121.21219, 28.326251],
                [121.212058, 28.324125],
                [121.208911, 28.32536],
                [121.205946, 28.318669],
                [121.198383, 28.307772],
                [121.186767, 28.294122],
                [121.177178, 28.282144],
                [121.173883, 28.277483],
                [121.160636, 28.275278],
                [121.155363, 28.274058],
                [121.150453, 28.269976],
                [121.147389, 28.263141],
                [121.146763, 28.258574],
                [121.145972, 28.246294],
                [121.146384, 28.236125],
                [121.143813, 28.2227],
                [121.142726, 28.209462],
                [121.138376, 28.180006],
                [121.136827, 28.168563],
                [121.134636, 28.162269],
                [121.131308, 28.158559],
                [121.123267, 28.148099],
                [121.099278, 28.133411],
                [121.090216, 28.127147],
                [121.079028, 28.117781],
                [121.070873, 28.110513],
                [121.059108, 28.096368],
                [121.039419, 28.055864],
                [121.030934, 28.033655],
                [121.024574, 28.003743],
                [121.015248, 27.981633],
                [120.991835, 27.949746],
                [120.984635, 27.960601],
                [120.973975, 27.964538],
                [120.96666, 27.966091],
                [120.957367, 27.969839],
                [120.948503, 27.972961],
                [120.929687, 27.977995],
                [120.920229, 27.978748],
                [120.910311, 27.98179],
                [120.89189, 27.98466],
                [120.883817, 27.984205],
                [120.870718, 27.984064],
                [120.854192, 27.982872],
                [120.848821, 27.980975],
                [120.839545, 27.981853],
                [120.833201, 27.980379],
                [120.82259, 27.977885],
                [120.81371, 27.97834],
                [120.807333, 27.982025],
                [120.805307, 27.983374],
                [120.793658, 27.996734],
                [120.791384, 28.003304],
                [120.788946, 28.008165],
                [120.785601, 28.011849],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 330383,
          name: "龙港市",
          center: [120.553039, 27.578156],
          centroid: [120.578115, 27.532706],
          childrenNum: 0,
          level: "district",
          subFeatureIndex: 11,
          acroutes: [100000, 330000, 330300],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.681108, 27.512956],
                [120.673776, 27.505002],
                [120.667383, 27.50182],
                [120.662869, 27.500686],
                [120.662523, 27.498717],
                [120.665637, 27.498323],
                [120.667713, 27.499363],
                [120.667845, 27.496228],
                [120.670448, 27.496858],
                [120.670761, 27.495503],
                [120.66763, 27.494227],
                [120.669641, 27.490226],
                [120.668454, 27.48947],
                [120.664829, 27.49199],
                [120.663544, 27.488446],
                [120.661089, 27.485012],
                [120.65585, 27.484507],
                [120.657662, 27.481514],
                [120.654235, 27.48112],
                [120.658404, 27.480364],
                [120.654993, 27.477654],
                [120.653362, 27.478032],
                [120.653593, 27.475385],
                [120.655389, 27.473636],
                [120.654235, 27.468106],
                [120.653148, 27.467633],
                [120.650693, 27.470312],
                [120.647282, 27.471825],
                [120.642553, 27.468374],
                [120.636177, 27.469902],
                [120.630822, 27.470107],
                [120.63163, 27.468957],
                [120.627148, 27.467665],
                [120.623457, 27.472392],
                [120.622634, 27.470895],
                [120.619305, 27.470659],
                [120.619091, 27.468988],
                [120.616999, 27.469146],
                [120.617032, 27.474472],
                [120.61227, 27.476299],
                [120.606965, 27.476709],
                [120.606322, 27.478536],
                [120.603307, 27.477355],
                [120.600588, 27.474487],
                [120.59843, 27.470296],
                [120.599797, 27.468295],
                [120.597178, 27.467271],
                [120.593091, 27.470422],
                [120.590554, 27.470454],
                [120.588231, 27.46891],
                [120.585908, 27.470454],
                [120.588247, 27.473258],
                [120.582959, 27.477591],
                [120.584952, 27.479545],
                [120.584145, 27.481183],
                [120.586402, 27.481498],
                [120.591444, 27.484807],
                [120.586765, 27.488068],
                [120.587934, 27.490242],
                [120.586682, 27.490825],
                [120.587012, 27.494338],
                [120.588346, 27.498039],
                [120.586633, 27.499016],
                [120.588462, 27.504655],
                [120.588066, 27.507616],
                [120.586122, 27.507538],
                [120.586452, 27.510924],
                [120.587803, 27.513271],
                [120.58978, 27.514043],
                [120.585776, 27.514499],
                [120.58632, 27.51683],
                [120.580009, 27.516515],
                [120.579696, 27.51809],
                [120.576071, 27.519067],
                [120.576088, 27.523429],
                [120.567652, 27.521004],
                [120.567866, 27.520201],
                [120.561918, 27.519287],
                [120.560617, 27.522988],
                [120.557914, 27.524012],
                [120.554306, 27.523083],
                [120.553812, 27.525209],
                [120.550418, 27.52428],
                [120.549083, 27.525004],
                [120.550187, 27.527508],
                [120.544964, 27.528154],
                [120.545112, 27.527335],
                [120.540977, 27.527067],
                [120.540829, 27.528721],
                [120.538209, 27.528799],
                [120.537484, 27.530673],
                [120.53409, 27.531256],
                [120.532706, 27.530311],
                [120.530959, 27.532626],
                [120.529822, 27.52913],
                [120.52656, 27.528673],
                [120.526379, 27.527193],
                [120.530646, 27.52198],
                [120.529789, 27.518783],
                [120.521205, 27.516893],
                [120.520085, 27.515019],
                [120.521831, 27.514295],
                [120.520513, 27.510845],
                [120.515966, 27.509979],
                [120.515949, 27.507207],
                [120.5144, 27.506955],
                [120.516575, 27.504387],
                [120.51786, 27.499174],
                [120.516114, 27.500323],
                [120.516081, 27.497803],
                [120.514664, 27.495377],
                [120.509013, 27.49637],
                [120.507266, 27.49544],
                [120.502883, 27.49596],
                [120.498023, 27.495094],
                [120.492454, 27.491203],
                [120.486209, 27.490872],
                [120.484414, 27.494196],
                [120.484315, 27.496637],
                [120.480986, 27.49585],
                [120.476834, 27.496716],
                [120.476653, 27.499378],
                [120.472353, 27.498906],
                [120.468118, 27.501032],
                [120.46881, 27.501962],
                [120.466141, 27.506026],
                [120.472583, 27.509144],
                [120.479141, 27.5142],
                [120.481085, 27.517712],
                [120.477279, 27.521964],
                [120.483689, 27.52439],
                [120.486671, 27.524421],
                [120.486638, 27.526532],
                [120.488977, 27.531146],
                [120.489735, 27.537996],
                [120.490806, 27.539145],
                [120.485386, 27.540767],
                [120.488104, 27.544782],
                [120.489192, 27.548781],
                [120.488351, 27.552056],
                [120.488763, 27.556244],
                [120.494678, 27.560637],
                [120.490938, 27.567076],
                [120.483722, 27.571027],
                [120.478927, 27.578189],
                [120.486111, 27.577182],
                [120.493541, 27.578882],
                [120.497595, 27.577622],
                [120.500791, 27.574458],
                [120.502422, 27.571231],
                [120.504432, 27.563817],
                [120.507068, 27.563093],
                [120.512291, 27.564982],
                [120.515933, 27.569059],
                [120.515307, 27.572333],
                [120.511814, 27.577197],
                [120.510973, 27.580361],
                [120.513231, 27.58417],
                [120.516295, 27.586122],
                [120.519623, 27.586988],
                [120.524352, 27.586956],
                [120.530827, 27.584674],
                [120.53699, 27.584218],
                [120.541092, 27.586028],
                [120.547749, 27.592748],
                [120.552098, 27.592685],
                [120.556563, 27.588735],
                [120.557124, 27.586327],
                [120.561029, 27.579511],
                [120.564769, 27.576473],
                [120.573715, 27.576473],
                [120.580751, 27.579795],
                [120.589104, 27.586594],
                [120.591015, 27.587476],
                [120.603587, 27.587287],
                [120.608678, 27.585461],
                [120.617509, 27.578315],
                [120.636342, 27.566477],
                [120.637017, 27.561471],
                [120.642817, 27.556575],
                [120.647727, 27.546766],
                [120.662292, 27.519634],
                [120.666675, 27.516862],
                [120.675078, 27.516877],
                [120.678175, 27.516421],
                [120.681108, 27.512956],
              ],
            ],
            [
              [
                [120.704735, 27.521508],
                [120.703499, 27.522248],
                [120.707273, 27.524201],
                [120.708986, 27.522374],
                [120.704735, 27.521508],
              ],
            ],
          ],
        },
      },
    ],
  });
});
